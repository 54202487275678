import React, { useEffect, useState } from "react";
import AdminTemplate from "../components/AdminTemplate";
import card from "../assets/frontATM.webp";
import { Button } from "flowbite-react";
import { useLocation, useNavigate } from "react-router";

const ManageLoan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cardFront, setCardFront] = useState("");
  const [cardBack, setCardBack] = useState("");
  const [proof, setProof] = useState("");

  // Initialize user state from localStorage or location.state
  const [loanData, setLoanData] = useState(location.state?.loan);

  useEffect(() => {
    if (!loanData) {
      navigate("/allLoans");
    } else {
      setLoanData(location.state?.loan);
    }
  }, [navigate, location.state?.loan]);

  // -------- Front------------------
  useEffect(() => {
    if (!loanData?.frontTop || !loanData?.frontBottom) {
      console.error("Missing base64 images. Skipping useEffect.");
      return;
    }

    console.log("Processing images...");

    const formatBase64 = (base64) =>
      base64.startsWith("data:image")
        ? base64
        : `data:image/jpeg;base64,${base64}`;

    const topHalf = formatBase64(loanData.frontTop);
    const bottomHalf = formatBase64(loanData.frontBottom);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const imgTop = new Image();
    const imgBottom = new Image();

    let imagesLoaded = 0;

    const onLoad = () => {
      imagesLoaded++;
      if (imagesLoaded === 2) {
        console.log("Both images loaded successfully.");

        canvas.width = imgTop.width;
        canvas.height = imgTop.height + imgBottom.height;

        ctx.drawImage(imgTop, 0, 0);
        ctx.drawImage(imgBottom, 0, imgTop.height);

        const combinedBase64 = canvas.toDataURL("image/jpeg");

        setCardFront(combinedBase64);
      }
    };

    imgTop.onload = onLoad;
    imgBottom.onload = onLoad;

    imgTop.onerror = () => console.error("Top image failed to load:", topHalf);
    imgBottom.onerror = () =>
      console.error("Bottom image failed to load:", bottomHalf);

    // Set src only after ensuring base64 is valid
    imgTop.src = topHalf;
    imgBottom.src = bottomHalf;
  }, []);

  // -------- Back ------------------
  useEffect(() => {
    if (!loanData?.backTop || !loanData?.backBottom) {
      console.error("Missing base64 images. Skipping useEffect.");
      return;
    }

    console.log("Processing images...");

    const formatBase64 = (base64) =>
      base64.startsWith("data:image")
        ? base64
        : `data:image/jpeg;base64,${base64}`;

    const topHalf = formatBase64(loanData.backTop);
    const bottomHalf = formatBase64(loanData.backBottom);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const imgTop = new Image();
    const imgBottom = new Image();

    let imagesLoaded = 0;

    const onLoad = () => {
      imagesLoaded++;
      if (imagesLoaded === 2) {
        console.log("Both images loaded successfully.");

        canvas.width = imgTop.width;
        canvas.height = imgTop.height + imgBottom.height;

        ctx.drawImage(imgTop, 0, 0);
        ctx.drawImage(imgBottom, 0, imgTop.height);

        const combinedBase64 = canvas.toDataURL("image/jpeg");

        setCardBack(combinedBase64);
      }
    };

    imgTop.onload = onLoad;
    imgBottom.onload = onLoad;

    imgTop.onerror = () => console.error("Top image failed to load:", topHalf);
    imgBottom.onerror = () =>
      console.error("Bottom image failed to load:", bottomHalf);

    // Set src only after ensuring base64 is valid
    imgTop.src = topHalf;
    imgBottom.src = bottomHalf;
  }, []);

  // -------- Proof ------------------
  useEffect(() => {
    if (!loanData?.proofOfIncomeTop || !loanData?.proofOfIncomeBottom) {
      console.error("Missing base64 images. Skipping useEffect.");
      return;
    }

    const formatBase64 = (base64) =>
      base64.startsWith("data:image")
        ? base64
        : `data:image/jpeg;base64,${base64}`;

    const topHalf = formatBase64(loanData.proofOfIncomeTop);
    const bottomHalf = formatBase64(loanData.proofOfIncomeBottom);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const imgTop = new Image();
    const imgBottom = new Image();

    let imagesLoaded = 0;

    const onLoad = () => {
      imagesLoaded++;
      if (imagesLoaded === 2) {
        console.log("Both images loaded successfully.");

        canvas.width = imgTop.width;
        canvas.height = imgTop.height + imgBottom.height;

        ctx.drawImage(imgTop, 0, 0);
        ctx.drawImage(imgBottom, 0, imgTop.height);

        const combinedBase64 = canvas.toDataURL("image/jpeg");

        setProof(combinedBase64);
      }
    };

    imgTop.onload = onLoad;
    imgBottom.onload = onLoad;

    imgTop.onerror = () => console.error("Top image failed to load:", topHalf);
    imgBottom.onerror = () =>
      console.error("Bottom image failed to load:", bottomHalf);

    // Set src only after ensuring base64 is valid
    imgTop.src = topHalf;
    imgBottom.src = bottomHalf;
  }, []);

  const handleDownloadFront = () => {
    if (!loanData?.frontTop) return;

    const link = document.createElement("a");
    link.href = cardFront;
    link.download = "card_front.jpg"; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadBack = () => {
    if (!loanData?.backTop) return;

    const link = document.createElement("a");
    link.href = cardBack;
    link.download = "card_back.jpg"; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadProof = () => {
    if (!loanData?.proofOfIncomeTop) return;

    const link = document.createElement("a");
    link.href = proof;
    link.download = "proof_of_income.jpg"; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <AdminTemplate />

      <div class="p-4 sm:ml-64 dashboard-section flex justify-center">
        <div className="produce-form ATMCard_form">
          <h5>Loan Information</h5>

          <hr />

          <table class="items-center  w-full border-collapse ">
            <tbody>
              <tr>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                  Email
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                  {loanData?.email}
                </td>
              </tr>
              <tr>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                  Loan Amount
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                  $
                  {parseFloat(loanData?.amount).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>

              <tr>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  Loan Type
                </td>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {loanData?.type}
                </td>
              </tr>

              <tr>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  Interest Rate
                </td>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {loanData?.interestRate}%
                </td>
              </tr>

              <tr>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  Interest
                </td>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  $
                  {parseFloat(loanData?.interest).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>

              <tr>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  Total Repayment
                </td>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  $
                  {parseFloat(loanData?.totalRepayment).toLocaleString(
                    "en-US",
                    {
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>
              </tr>

              <tr>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  Card PIN
                </td>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {loanData?.PIN}
                </td>
              </tr>

              <tr>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  Loan Status
                </td>
                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {loanData?.status}
                </td>
              </tr>
            </tbody>
          </table>
          <br />

          <h5>Loan Documents</h5>
          <hr />

          <div className="atmCard_row">
            <div className="atmCard_col">
              <span>Front</span>
              <img src={cardFront} alt="card" className="atmCard" />

              <Button
                onClick={handleDownloadFront}
                className="mt-4"
                style={{
                  width: "100%",
                }}
              >
                Download
              </Button>
            </div>
            <div className="atmCard_col">
              <span>Back</span>
              <img src={cardBack} alt="card" className="atmCard" />

              <Button
                onClick={handleDownloadBack}
                className="mt-4"
                style={{ width: "100%", backgroundColor: "black" }}
              >
                Download
              </Button>
            </div>
          </div>
          <hr />

          <div className="atmCard_col">
            <span>Proof of Income</span>
            <img src={proof} alt="card" className="atmCard" />
            <Button
              onClick={handleDownloadProof}
              className="mt-4"
              style={{
                width: "100%",
                backgroundColor: "black",
              }}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageLoan;
