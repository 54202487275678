import HistorySection from "../components/HistorySection";
import Template from "../components/Template";

const TransactionHistory = () => {
  return (
    <>
      <Template />
      <HistorySection />
    </>
  );
};

export default TransactionHistory;
