import Template from "../components/Template";
import KYCSection from "../components/KYCSection";

const KYC = () => {
  return (
    <>
      <Template />
      <KYCSection />
    </>
  );
};

export default KYC;
