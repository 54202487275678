import { useEffect, useState } from "react";
import AdminTemplate from "../components/AdminTemplate";
import MessageAlert from "../components/MessageAlert";
import Loader from "../components/Loader";
import useAuthStore from "../stores/authStore";

const UpdateCrypto = () => {
  const user = useAuthStore((state) => state.user);
  const cryptos = useAuthStore((state) => state.cryptoTypes);
  const setCrypto = useAuthStore((state) => state.setCrypto);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [formData, setFormData] = useState({
    email: "info@optimaholdin.com",
    btc: "",
    eth: "",
    usdt: "",
  });
  const [cryptoData, setCryptoData] = useState(cryptos);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // console.log(cryptoData);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      if (user?.role !== "super") {
        throw new Error("Cannot perform action");
      }

      if (formData.btc === "" && formData.eth === "" && formData.usdt === "") {
        throw new Error("All fields cannot be blank");
      }

      const response = await fetch(`https://stanbic-api.bitinverse.com/api/v1/setcrypto`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setCryptoData(result);
      setCrypto(result);
      setMessage("Crypto Update Successful!");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsLoading(false);
      setFormData({
        email: "info@globalstanbic.com",
        btc: "",
        eth: "",
        usdt: "",
      });
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  return (
    <>
      <AdminTemplate />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 sm:ml-64 dashboard-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form class="" onSubmit={handleSubmit}>
              <h3>Update Crypto Address</h3>
              <div class="mb-4 mt-8">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Bitcoin(BTC)
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="btc"
                  value={formData.btc}
                  onChange={handleChange}
                  placeholder={cryptoData?.btc}
                />
              </div>

              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Ethereum (ETH)
                </label>
                <input
                  type="text"
                  id="price"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="eth"
                  value={formData.eth}
                  onChange={handleChange}
                  placeholder={cryptoData?.eth}
                />
              </div>

              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  USDT (TRC20)
                </label>
                <input
                  type="text"
                  id="price"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="usdt"
                  value={formData.usdt}
                  onChange={handleChange}
                  placeholder={cryptoData?.usdt}
                />
              </div>

              <button
                style={{ backgroundColor: "#216206", marginBottom: "30px" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : "Update"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCrypto;
