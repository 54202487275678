import { useLocation, useNavigate } from "react-router";
import FormNavbar from "../components/FormNavbar";
import { useEffect } from "react";
import NewFooter from "../components/NewFooter";

const RegError = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.checkData) return navigate("/");
  }, []);

  return (
    <>
      <FormNavbar />

      <div className="loginSection_wrapper">
        <div className="loginSection_form text-center">
          <h2 className="loginSection_title">Review Process</h2>
          <p className="loginSection_notice_text" style={{ marginTop: "10px" }}>
            Your online banking account with Optimaholdin is currently under
            review, our online banking team will review your application, you
            will be notified once the account is active.
          </p>
        </div>
      </div>

      <NewFooter />
    </>
  );
};

export default RegError;
