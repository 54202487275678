import { useEffect, useState } from "react";
import CountryList from "../components/CountryList";
import FormNavbar from "../components/FormNavbar";
import NewFooter from "../components/NewFooter";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import useAuthStore from "../stores/authStore";
import MessageAlert from "../components/MessageAlert";
import avatar from "../assets/profile.png";

import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Country, State, City } from "country-state-city";

const SignUp = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [profileImg, setProfileImg] = useState("");
  const [imgUpdated, setImgUpdated] = useState(false);

  // Country and State
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [isLoading, setIsLoading] = useState(false); // Corrected `islaoding`
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    gender: "",
    email: "",
    phone: "",
    profilePicture: profileImg,
    dob: "",
    homeAddress: "",
    city: selectedCity,
    state: selectedState,
    zipCode: "",
    country: selectedCountry,
    accountType: "",
    password: "",
    confirmPassword: "",
  });
  const [value, setValue] = useState();
  const [showpassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const countries = Country.getAllCountries();
  const states = selectedCountry
    ? State.getStatesOfCountry(selectedCountry)
    : [];
  const cities = selectedState
    ? City.getCitiesOfState(selectedCountry, selectedState)
    : [];

  const togglePasswordType = () => {
    setShowPassword(!showpassword);
  };

  const toggleConfirmPasswordType = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const setPhoneNumber = (value) => {
    setValue(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value,
    }));
  };

  function validatePassword(password) {
    const minLength = 8;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecialChar = /[\W_]/.test(password);

    if (password.length < minLength) {
      throw new Error("Password must be at least 8 characters long.");
    }
    if (!hasLowercase) {
      throw new Error("Password must include at least one lowercase letter.");
    }
    if (!hasUppercase) {
      throw new Error("Password must include at least one uppercase letter.");
    }
    if (!hasSpecialChar) {
      throw new Error("Password must include at least one special character.");
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn, navigate]);

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (isLoggedIn) {
      if (user.role === "admin") {
        navigate("/allusers");
      } else {
        navigate("/dashboard");
      }
    }
  }, [isLoggedIn, user?.role, navigate]);

  // -------------- Handle submit -----------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    const correctNumber = formData.phone && isValidPhoneNumber(formData.phone);

    // Check if image processing is done
    if (!imgUpdated && profileImg) {
      setMessage("Please wait for the profile picture to finish uploading.");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }

    setIsLoading(true);

    try {
      if (formData.password !== formData.confirmPassword) {
        throw new Error("Passwords do not match!");
      } else if (!correctNumber) {
        throw new Error("Invalid phone number");
      } else if (!imgUpdated) {
        throw new Error("Please upload a profile picture");
      }
      validatePassword(formData.password);

      const response = await fetch(
        `https://stanbic-api.bitinverse.com/api/v1/handleverifyemail`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: formData.email }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setIsLoading(false);
      const data = { formData, result };

      navigate("/validate", { state: { data } });
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  // -------------- Grab image and set the profile image ----------------------
  const handleAddProfile = (e) => {
    const file = e.target.files[0];

    if (file.size > 2 * 1024 * 1024) {
      setMessage("File size should not exceed 2MB");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the desired dimensions (resize/compress)
        const maxWidth = 300;
        const maxHeight = 300;
        let width = image.width;
        let height = image.height;

        // Maintain aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);
        setProfileImg(compressedBase64);
        setFormData((prevFormData) => ({
          ...prevFormData,
          profilePicture: compressedBase64,
        }));

        setImgUpdated(true); // Set to true after the image is fully processed
      };
    };
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 6000); // Hide message after 6 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  const handleSetCountry = (e) => {
    const value = e.target.value;
    const country = countries.find((c) => c.isoCode === value);
    setSelectedCountry(country.isoCode);

    setFormData((prevFormData) => ({
      ...prevFormData,
      country: country.name,
    }));
  };

  const handleSetState = (e) => {
    const value = e.target.value;
    const state = states.find((c) => c.isoCode === value);
    setSelectedState(state.isoCode);

    setFormData((prevFormData) => ({
      ...prevFormData,
      state: state.name,
    }));
  };

  const handleSetCity = (e) => {
    const value = e.target.value;
    const city = cities.find((c) => c.name === value);
    setSelectedCity(city.name);

    setFormData((prevFormData) => ({
      ...prevFormData,
      city: city.name,
    }));
  };

  return (
    <>
      <FormNavbar />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper form-wrapper-aux">
          <div className="produce-form">
            <form class="" onSubmit={handleSubmit}>
              <div className="loginSection_notice">
                <h3 className="loginSection_notice_title">LET'S GET STARTED</h3>
                <p className="loginSection_notice_text">
                  We respect your privacy and will never sell or lease your
                  email for any reason.
                </p>
              </div>

              <h2>REGISTER</h2>
              <h3 className="mb-8 dashboard-label">Personal Information</h3>

              <div className="avatarWrapper flex justify-center">
                <div
                  className="profileBox"
                  style={{
                    backgroundImage: `url('${
                      profileImg ? profileImg : avatar
                    }')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <input
                    type="file"
                    id="profileImage"
                    accept=".jpg, .jpeg, .png"
                    name="profilePicture"
                    onChange={handleAddProfile}
                    hidden
                  />
                  <label
                    htmlFor="profileImage"
                    className="avatarInputIcon"
                    style={{ padding: "1px 10px" }}
                  >
                    <i className="b bi-pencil "></i>
                  </label>
                </div>
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="secondName"
                  value={formData.secondName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Gender
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"gender"}
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">-- select --</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="I prefer not to say">
                    I prefer not to say
                  </option>
                </select>
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Phone Number
                </label>
                {/* <input
                  type="number"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                /> */}

                <PhoneInput
                  placeholder="Enter phone number"
                  value={value}
                  onChange={setPhoneNumber}
                  defaultCountry="US"
                  international
                  countryCallingCodeEditable={false}
                  error={value && isValidPhoneNumber(value) ? "true" : "false"}
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* -------------------- Address Information ------------------------ */}
              <h3 className="mb-8 dashboard-label">Address Information</h3>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Country of Residence
                </label>

                <select
                  value={selectedCountry}
                  onChange={(e) => handleSetCountry(e)}
                  style={{ width: "100%" }}
                  required
                >
                  <option value="">Select Country</option>
                  {countries.map((c) => (
                    <option key={c.isoCode} value={c.isoCode}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  State
                </label>

                <select
                  style={{ width: "100%" }}
                  value={selectedState}
                  onChange={(e) => handleSetState(e)}
                  disabled={!selectedCountry}
                >
                  <option value="">Select State</option>
                  {states.map((s) => (
                    <option key={s.isoCode} value={s.isoCode}>
                      {s.name}
                    </option>
                  ))}
                </select>
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  City Name
                </label>

                <select
                  style={{ width: "100%" }}
                  value={selectedCity}
                  onChange={(e) => handleSetCity(e)}
                  disabled={!selectedState}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.name} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Zip code / Postal code
                </label>
                <input
                  type="number"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Home Address
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="homeAddress"
                  value={formData.homeAddress}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* -------------------- Account Information ------------------------ */}
              <h3 className="mb-8 dashboard-label">Account Information</h3>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Account Type
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"accountType"}
                  value={formData.accountType}
                  onChange={handleChange}
                >
                  <option value="">-- select --</option>
                  <option value="savings">Savings Account</option>
                  <option value="current">Current Account</option>
                  <option value="business">Business Account</option>
                </select>
              </div>
              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Password
                </label>

                <div style={{ position: "relative" }}>
                  <input
                    type={showpassword ? "text" : "password"}
                    id="availability"
                    class="shadow-sm produce-input block w-full p-2.5 "
                    // placeholder="Enter availability"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />

                  {showpassword ? (
                    <li
                      className="bi bi-eye eye-drop"
                      style={{
                        position: "absolute",
                        right: 24,
                        top: 8,
                        color: "gray",
                        fontSize: "23px",
                      }}
                      onClick={togglePasswordType}
                    ></li>
                  ) : (
                    <li
                      className="bi bi-eye-slash eye-drop"
                      style={{
                        position: "absolute",
                        right: 24,
                        top: 8,
                        color: "gray",
                        fontSize: "23px",
                      }}
                      onClick={togglePasswordType}
                    ></li>
                  )}
                </div>
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Confirm Password
                </label>

                <div style={{ position: "relative" }}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="availability"
                    class="shadow-sm produce-input block w-full p-2.5 "
                    // placeholder="Enter availability"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />

                  {showConfirmPassword ? (
                    <li
                      className="bi bi-eye eye-drop"
                      style={{
                        position: "absolute",
                        right: 24,
                        top: 8,
                        color: "gray",
                        fontSize: "23px",
                      }}
                      onClick={toggleConfirmPasswordType}
                    ></li>
                  ) : (
                    <li
                      className="bi bi-eye-slash eye-drop"
                      style={{
                        position: "absolute",
                        right: 24,
                        top: 8,
                        color: "gray",
                        fontSize: "23px",
                      }}
                      onClick={toggleConfirmPasswordType}
                    ></li>
                  )}
                </div>
              </div>

              <button
                style={{
                  backgroundColor: "#0b111e",
                  height: 50,
                  marginTop: 20,
                  textTransform: "uppercase",
                  fontSize: 12,
                }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={isLoading || (!imgUpdated && profileImg)} // Disable if loading or image is not ready
              >
                {isLoading ? <Loader /> : "Create  Account"}
              </button>

              <div className="loginsection_form_question">
                <span>
                  Already have account? <Link to={"/login"}>login</Link>{" "}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <NewFooter />
    </>
  );
};

export default SignUp;
