import Navbar from "../components/Navbar";
import NewFooter from "../components/NewFooter";
import bgImg from "../assets/who-we-are-header.webp";
import img1 from "../assets/Group.svg";

const AboutUs = () => {
  return (
    <>
      <div className="bg-white">
        <Navbar />
        <div
          className=" bg-[#0B111E] flex flex-col md:flex-row"
          id="about_banner"
        >
          <div className="flex-1 text-white banner_column">
            <span className="banner_title">
              We’re on a mission to use finance as a force for good.
            </span>

            <p className="banner_subtitle">
              We didn't set out to build a bank. We set out to build a better
              world. For over a decade, we’ve been leading the way in
              climate-friendly personal finance, empowering our customers to
              directly combat climate change.
            </p>
          </div>

          {/* -------Column 2-------- */}
          <div className="flex-1"></div>
        </div>

        {/* ============ Text Section ==================== */}
        <div className="about_section">
          <div className="about_col">
            <ul className="about_ul">
              {/* ------------ List Item-------------- */}
              <li className="about_li">
                <div className="about_icon">
                  <img
                    src={img1}
                    alt="icon"
                    style={{ height: "20px", width: "100px" }}
                  />
                </div>

                <div className="about_text">
                  <span id="about_title">Our Promise</span>
                  <h5 id="about_subtitle">Built on Trust</h5>
                  <p id="about_desc">
                    Our mission is to be a financial partner you can trust to
                    help you do well for yourself and do good for the world. We
                    do it by bringing fairness, sustainability, and a social
                    conscience to personal finance.
                  </p>
                </div>
              </li>

              {/* ------------ List Item-------------- */}
              <li className="about_li">
                <div className="about_icon">
                  <img
                    src={img1}
                    alt="icon"
                    style={{ height: "20px", width: "100px" }}
                  />
                </div>

                <div className="about_text">
                  <span id="about_title">Our Purpose</span>
                  <h5 id="about_subtitle">A Climate-Friendly Future</h5>
                  <p id="about_desc">
                    Mitigating the impact of climate change is going to require
                    all of us to do our part. That’s why we’re empowering
                    everyone to take bold climate action today through financial
                    services that don't harm the planet.
                  </p>
                </div>
              </li>

              {/* ------------ List Item-------------- */}
              <li className="about_li">
                <div className="about_icon">
                  <img
                    src={img1}
                    alt="icon"
                    style={{ height: "20px", width: "100px" }}
                  />
                </div>

                <div className="about_text">
                  <span id="about_title">Our Conscience</span>
                  <h5 id="about_subtitle">Make Money and Make a Difference</h5>
                  <p id="about_desc">
                    We help you spend, save, and invest with a conscience so you
                    can grow your money while making the world a better place.
                    We keep your deposits fossil fuel free by committing to
                    never fund projects like pipelines or oil drilling.†
                  </p>
                </div>
              </li>

              {/* ------------ List Item-------------- */}
              <li className="about_li">
                <div className="about_icon">
                  <img
                    src={img1}
                    alt="icon"
                    style={{ height: "20px", width: "100px" }}
                  />
                </div>

                <div className="about_text">
                  <span id="about_title">Our Pledge</span>
                  <h5 id="about_subtitle">Committed to Giving Back</h5>
                  <p id="about_desc">
                    Ten percent of the monthly Pay What Is Fair¹ contribution
                    you choose to pay is donated to non-profits that support
                    climate action. Previous donation recipients include
                    organizations like SeaTrees, who protect and regenerate blue
                    carbon coastal ecosystems. And Rainforest Connection, who
                    prevent illegal deforestation and stop animal poaching.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <NewFooter />
      </div>
    </>
  );
};

export default AboutUs;
