import { useNavigate } from "react-router";
import card from "../assets/cards-angled.webp";

const MoreCard = () => {
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate("/signup");
  };


  return (
    <>
      <div className="more_card_section">
        <div className="more_card_container">
          <div className="more_card_row">
            <div className="more_card_col">
              <img src={card} alt="card" id="more_card_img" />
            </div>

            <div className="more_card_col">
              <div className="more_card_text">
                <h2 id="more_card_title">
                  Put a new kind of
                  <span
                    style={{ color: "rgb(178, 223, 206)", fontWeight: 700 }}
                  >
                    {" "}
                    change{" "}
                  </span>
                  in your pocket
                </h2>

                <p>
                  When you open an OptimaHoldin account, you get a lot more than a
                  new debit card. You’re joining a community of like-minded
                  people who are committed to making climate action part of
                  their everyday lives. In just a decade, OptimaHoldin members
                  have planted more than 30 million carbon-absorbing trees²
                  through everyday spending, and we’re just getting started.
                  Will you join us?
                </p>

                <button onClick={handleSignup} className="more_card_btn">GET STARTED </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreCard;
