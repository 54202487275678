import { Link, useNavigate } from "react-router-dom";
import Card from "../assets/debit-cards-ripple_3x.webp";
import img1 from "../assets/banner_1.webp"
import img2 from "../assets/banner_2.webp"
import img3 from "../assets/banner_3.webp"
import img4 from "../assets/banner_4.webp"
import CroppedCard from "../assets/debit-cards-ripple-clipped.webp";

const Banner = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <>
      <div className="hero">
        <div
          className="min-h-screen bg-[#0B111E] flex flex-col md:flex-row"
          style={{
            position: "relative",
          }}
        >
          <div className="flex-1 text-white banner_column">
            <span className="banner_title">Climate-Friendly Banking</span>

            <p className="banner_subtitle">
              OptimaHoldin is the climate-friendly banking alternative that's good
              for your wallet and the planet. Enjoy no mandatory fees while
              earning cash back³, planting trees², and keeping your money from
              funding fossil fuels.†
            </p>

            <input type="text" placeholder="Email Address" className="banner_input"/><br />
            <p className="banner_error">Your email is required.</p>

            <button onClick={handleSignup} className="banner_button">get started</button>


            <div className="banner_pictures_wrapper">
              <img src={img1} alt="icon" className="banner_img"/>
              <img src={img2} alt="icon" className="banner_img"/>
              <img src={img3} alt="icon" className="banner_img"/>
              <img src={img4} alt="icon" className="banner_img"/>
            </div>


          </div>

          {/* -------Column 2-------- */}
          <div className="flex-1">
            <img src={Card} alt="card" className="banner_debit_card" />
            <img src={CroppedCard} alt="card" className="showMobile" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
