import { useEffect } from "react";
import useAuthStore from "../stores/authStore";

const useInactivity = (timeoutDuration = 3600000) => {
  const logout = useAuthStore((state) => state.logout);

  useEffect(() => {
    let inactivityTimeout;

    const clearLocalStorage = () => {
      console.log("User inactive for 1 hour. Clearing local storage...");
      logout();
      window.location.href = "/";
    };

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(clearLocalStorage, timeoutDuration);
    };

    // List of events indicating activity
    const activityEvents = ["mousemove", "keydown", "click", "scroll"];

    // Attach event listeners
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetInactivityTimer)
    );

    // Set the initial timeout
    resetInactivityTimer();

    // Cleanup function to remove event listeners and clear timeout
    return () => {
      clearTimeout(inactivityTimeout);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetInactivityTimer)
      );
    };
  }, [timeoutDuration]);
};

export default useInactivity;
