import CardSection from "../components/CardSection";
import Template from "../components/Template";

const card = () => {
  return (
    <>
      <Template />
      <CardSection />
    </>
  );
};

export default card;
