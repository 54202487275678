import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import MissionSection from "../components/MissionSection";
import Footer from "../components/Footer";
import Sponsors from "../components/Sponsors";
import AdviseSection from "../components/AdviseSection";
import FeesSection from "../components/FeesSection";
import ProtectionSection from "../components/ProtectionSection";
import SecuritySection from "../components/SecuritySection";
import RatingSection from "../components/RatingSection";
import MoreCard from "../components/MoreCard";
import FeaturesSection from "../components/FeaturesSection";
import NewFooter from "../components/NewFooter";

const HomePage = () => {
  return (
    <div className="bg-white">
      <Navbar />
      <Banner />
      <Sponsors />
      <AdviseSection />
      <FeesSection />
      <ProtectionSection />
      <SecuritySection />
      <RatingSection />
      <MoreCard />
      <FeaturesSection />
      <NewFooter />
      {/* <MissionSection /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default HomePage;
