import LoanSection from "../components/LoanSection";
import Template from "../components/Template";

const Loan = () => {
  return (
    <>
      <Template />
      <LoanSection />
    </>
  );
};

export default Loan;
