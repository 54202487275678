import AdminTemplate from "../components/AdminTemplate"
import AllTransactionsSection from "../components/AllTransactionSection"

const AllTransactions = () => {
  return (
    <>
      <AdminTemplate />
      <AllTransactionsSection />
    </>
  );
};

export default AllTransactions;
