import icon1 from "../assets/Icon_Pill_3x.png";
import icon2 from "../assets/Icon_Pill_3x (1).png";
import icon3 from "../assets/Icon_Pill_money.png";
import icon4 from "../assets/Icon_Pill_card.png";
import badgeImg from "../assets/FDIC-badge-1250000-white_2x.webp";

const SecuritySection = () => {
  return (
    <>
      <div className="security_security_wrapper_desktop">
        <div className="security_section_container">
          <h2 className="security_sect_title">Security you can trust</h2>

          <div className="security_sect_row">
            {/* ------------ Col -------------- */}
            <div className="security_sect_col">
              <div className="security_sect_col_container">
                <div className="security_sect_col_icon">
                  <img src={icon1} alt="security_icon1" />
                </div>

                <div className="text-left">
                  <h5 className="security_sect_col_title">
                    $1.25 Million FDIC Insured†
                  </h5>
                  <p className="security_sect_col_text">
                    Your funds are FDIC insured up to $1,250,000 per depositor.
                  </p>
                </div>
              </div>
            </div>

            {/* ------------ Col -------------- */}
            <div className="security_sect_col">
              <div className="security_sect_col_container">
                <div className="security_sect_col_icon">
                  <img src={icon2} alt="security_icon1" />
                </div>

                <div className="text-left">
                  <h5 className="security_sect_col_title">Advanced Security</h5>
                  <p className="security_sect_col_text">
                    Secure two-factor and biometric authentication.
                  </p>
                </div>
              </div>
            </div>

            {/* ------------ Col -------------- */}
            <div className="security_sect_col">
              <div className="security_sect_col_container">
                <div className="security_sect_col_icon">
                  <img src={icon3} alt="security_icon1" />
                </div>

                <div className="text-left">
                  <h5 className="security_sect_col_title">
                    Purchase Protection
                  </h5>
                  <p
                    className="security_sect_col_text"
                    // style={{ width: "60%" }}
                  >
                    Mastercard® backed purchase protection including extended
                    warranty and satisfaction guarantee.
                  </p>
                </div>
              </div>
            </div>

            {/* ------------ Col -------------- */}
            <div className="security_sect_col">
              <div className="security_sect_col_container">
                <div className="security_sect_col_icon">
                  <img src={icon4} alt="security_icon1" />
                </div>

                <div className="text-left">
                  <h5 className="security_sect_col_title">
                    ID Theft Protection
                  </h5>
                  <p
                    className="security_sect_col_text"
                    // style={{ width: "60%" }}
                  >
                    Your OptimaHoldin Mastercard debit card comes with
                    Mastercard ID Theft Protection™.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <img src={badgeImg} alt="badge" id="badgeImg" />

          <p className="security_text">
            OptimaHoldin is not a Bank. OptimaHoldin Debit Cards are issued by
            Coastal Community Bank, Member FDIC, pursuant to a license by
            Mastercard International Incorporated. Through the Coastal Community
            Bank Insured Bank Deposit Program, cash balances in the OptimaHoldin
            Spend and Save Accounts are deposited at one or more FDIC-insured
            depository institutions (each a "Bank") up to $250,000 per Bank.
            With five Banks available, Deposits are FDIC-insured up to $1.25
            million per depositor. Visit fdic.gov. You are responsible for
            monitoring your deposits at each Bank to determine the available
            FDIC insurance coverage.
          </p>
        </div>
      </div>
    </>
  );
};

export default SecuritySection;
