import img1 from "../assets/1percent-for-the-planet-footer_3x.webp";
import img2 from "../assets/badge-climate-neutral-certified-horizontal-filled.webp";
import img3 from "../assets/b-corp-footer_3x.webp";
import img4 from "../assets/security-key-16x16.png";

const NewFooter = () => {
  return (
    <>
      <div className="newfooter_wrapper">
        <div className="newfooter_container">
          <div className="newfooter_img_wrapper">
            <img src={img1} alt="logo" className="newfooter_img" />
            <img src={img2} alt="logo" className="newfooter_img" />
            <img src={img3} alt="logo" className="newfooter_img" />
          </div>

          <div className="flex mt-4">
            <img src={img4} alt="logo" id="security_icon" />
            <span id="security_text">strong security</span>
          </div>
          <span id="security_text">256 Bit Encryption Monitored 24/7</span>

          <div className="newfooter_notes">
            <p>
              †OptimaHoldin is a financial technology company, not an FDIC-insured
              bank. Banking Services provided by Coastal Community Bank, Member
              FDIC. The OptimaHoldin Debit Mastercard® is issued by Coastal
              Community Bank, Member FDIC, pursuant to a license by Mastercard
              International Incorporated. FDIC insurance only covers the failure
              of an FDIC-insured bank. FDIC insurance is available through
              pass-through insurance at Coastal Community Bank, Member FDIC, if
              certain conditions have been met. Through the Coastal Community
              Bank Insured Bank Deposit Program, cash balances in the OptimaHoldin
              Spend and Save Accounts are deposited at one or more FDIC-insured
              depository institutions (each a "Bank") up to $250,000 per Bank.
              With five participating Banks available, Deposits are FDIC-insured
              up to $1.25 million per depositor. For more information, visit
              fdic.gov. You are responsible for monitoring your deposits at each
              Bank to determine the available FDIC insurance coverage.
              OptimaHoldin's Program Banks have formally committed that customer
              deposits will not be used for lending to oil and gas exploration,
              production or transportation, or coal mining.
            </p>

            <p>
              OptimaHoldin Debit Cards are issued by Coastal Community Bank,
              Member FDIC, pursuant to a license by Mastercard® International
              Incorporated. OptimaHoldin is under separate ownership from any
              other named entity. OptimaHoldin is not a bank.
            </p>

            <p>
              Apple and the Apple logo are trademarks of Apple Inc., registered
              in the U.S. and other countries. App Store is a service mark of
              Apple Inc., registered in the U.S. and other countries. Google
              Play and the Google Play logo are trademarks of Google Inc. All
              other product names, logos, brands, trademarks, and registered
              trademarks are property of their respective owners. All company,
              product, and service names used in this website are for
              identification purposes only. Use of these names, trademarks, and
              brands does not imply endorsement.
            </p>

            <p>
              1 Planet Protection offsets the estimated CO2 emissions from
              driving your vehicle based on purchases at gas stations. Verified
              carbon offsets are purchased quarterly, in aggregate, to account
              for estimated emissions from all Planet Protection subscribers.
              This feature is bundled into the OptimaHoldin Plus program and is
              not offered as a standalone feature that can be purchased
              separately.
            </p>

            <p>
              2 When you choose to add to your OptimaHoldin Debit Card to the
              Plant Your Change Service, we round up the amount of OptimaHoldin
              Debit Card transactions made using your OptimaHoldin Debit Card
              feature in your OptimaHoldin Spend Account to the next whole dollar
              amount (from $0.01 to $0.99), and transfer the amount in excess of
              the purchase price to an OptimaHoldin Plant Your Change Service
              Account. Even dollar transactions ($xx.00) are not considered
              qualifying transactions and are not eligible to be rounded up for
              this Service. The Plant Your Change Service Fee from Debit Card
              transactions will be deducted from your account once each business
              day and include all qualified transactions incurred since the last
              fee withdrawal. Funded trees can take up to 18 months to plant,
              allowing for time to set up nurseries, select/expand planting
              sites, take advantage of optimal planting seasons and accommodate
              for local/regional/country social, political and health
              considerations. Planting trees and restoring forests are among the
              best ways to draw down carbon from the atmosphere, because they
              can help mitigate the impacts of climate change and contribute to
              healthy ecosystems that support wildlife and people. Trees planted
              by our reforestation partners are not intended to be used as
              carbon offsets and are not verified as such by a third party.
              Planted trees are part of a reforestation program where
              approximately 20-30% more trees are planted to account for natural
              survival rates and unforeseen circumstances (i.e., wildfires,
              natural disasters, disease) so enough trees survive to reforest an
              area. However, there is no guarantee all trees planted will be
              permanent.
            </p>

            <p>
              3 OptimaHoldin’s Cash Back program is subject to change at any time
              and without notice, including reversal of rewards for abuse,
              fraud, and other illicit activity. Cash Back rewards are generally
              credited on the first day of each calendar month. For additional
              Cash Back details, click cash back on purchases.
            </p>

            <p>
              4 The OptimaHoldin Save Account’s Annual Percentage Yield (“APY”) is
              variable and accurate as of 02/11/2025. Rates are subject to
              change. To earn 1.00% APY (3.00% APY if customer is enrolled in
              OptimaHoldin Plus) interest on OptimaHoldin Save Account balances up
              to and including $10,000.00 in any calendar month, customer must
              have settled debit card transactions of $500.00 or more, monthly,
              on their OptimaHoldin debit card. Debit card transactions for money
              transfer vendors (for example, but not limited to, CashApp, Venmo,
              Facebook Pay) do not qualify towards the $500.00 spend
              requirement. Balances over $10,000.00 will earn 0.00% APY (0.25%
              APY if customer is enrolled in OptimaHoldin Plus). If the
              requirements are not met each month, the APY will be 0.00% APY
              (0.25% APY if customer is enrolled in OptimaHoldin Plus) on Save
              Account regardless of balance. Terms & FAQ.
            </p>

            <p>
              5 OptimaHoldin’s early direct deposit of funds service is not
              guaranteed, is subject to payor’s support and the timing of
              payor’s payment instruction, and is based on a comparison of our
              policy of making funds available upon our receipt of payment
              instruction with the typical banking practice of posting funds at
              settlement. OptimaHoldin generally makes these funds available on
              the day the payment file is received, which may be up to 2 days
              earlier than the scheduled payment date.
            </p>

            <p>© Copyright 2025 Mission Financial Partners, LLC</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewFooter;
