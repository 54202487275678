import img1 from "../assets/phone-checkmark-hand.svg";
import img2 from "../assets/deposit-paper-airlplane.svg";
import img3 from "../assets/shop-with-purpose.svg";

const FeaturesSection = () => {
  return (
    <>
      <div className="features_wrapper">
        <div className="features_container">
          <h2 className="features_title">Get started today</h2>

          <div className="features_row">
            {/* ------- Col ------- */}
            <div className="features_col">
              <div className="features_img_wrapper">
                <img src={img1} alt="phone-checkmark-hand" />
              </div>

              <div className="features_col_text">
                <span className="features_col_title">1. Create an account</span>
                <p>Sign up for an account and start using our services</p>
              </div>
            </div>
            {/* ------- Col ------- */}
            <div className="features_col">
              <div className="features_img_wrapper">
                <img src={img2} alt="phone-checkmark-hand" />
              </div>

              <div className="features_col_text">
                <span className="features_col_title">2. Make a deposit</span>
                <p>Transfer money from your old bank to fund your account.</p>
              </div>
            </div>
            {/* ------- Col ------- */}
            <div className="features_col">
              <div className="features_img_wrapper">
                <img src={img3} alt="phone-checkmark-hand" />
              </div>

              <div className="features_col_text">
                <span className="features_col_title">3. Shop with purpose</span>
                <p>Use your digital card while we mail your physical card.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesSection;
