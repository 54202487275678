import Template from "../components/Template";
import success from "../assets/success.png";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";

const SuccessfulTransfer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const transferData = location.state?.result.newTransfer;
  const charge = location.state?.charge;

 
  useEffect(() => {
    if(!transferData) return navigate("/dashboard")
  }, [])

  const handleCancle = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <Template />

      <div class="p-4 sm:ml-64 content-section ">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form pt-2" style={{ color: "gray" }}>
            <div className="flex justify-end">
              <i
                class="bi bi-x-lg"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={handleCancle}
              ></i>
            </div>
            <div className="flex justify-center">
              <img
                src={success}
                alt="done"
                style={{ width: "80px", height: "auto" }}
              />
            </div>
            <div className="text-center ">
              <span>Transaction Succesful</span>
            </div>
            <br />
            <h5 style={{ color: "#5e5f62" }}>Sent to:</h5>
            <span>{transferData?.accountHolder}</span> <br />
            <span>{transferData?.accountNumber}</span>
            <br />
            <br />
      
            <h5 style={{ color: "#5e5f62" }}>Amount:</h5>
            <span>${transferData?.amount.toLocaleString()}</span>
            <br />
            <br />

            <h5 style={{ color: "#5e5f62" }}>Reference ID:</h5>
            <span>{transferData?.referenceId}</span>
            <br />
            <br />
            <h5 style={{ color: "#5e5f62" }}>Fee / Charge:</h5>
            <span>${charge.toLocaleString()}</span>
            <br />
            <br />
            <h5 style={{ color: "#4CAF50" }}>Total Amount:</h5>
            <span>${(parseInt(transferData?.amount) + parseFloat(charge)).toLocaleString()}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessfulTransfer;
