import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useUserStore from "../stores/usersStore";
import moment from "moment";

const AdminUsersSection = () => {
  const navigate = useNavigate();
  const updateUsers = useUserStore((state) => state.updateUsers);
  const users = useUserStore((state) => state.users);

  const [combinedImage, setCombinedImage] = useState(null);

  const handleManage = (aUser) => {
    localStorage.removeItem("managedData");
    navigate("/manageuser", { state: { aUser } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await (
          await fetch(`https://stanbic-api.bitinverse.com/api/v1/getallusers`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
        ).json();

        updateUsers(result);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div class="p-4 sm:ml-64 content-section" id="content-pad">
        <h3 className=" mb-2  dashboard-label">All Users</h3>

        <div class="block w-full overflow-x-auto">
          <table class="items-center  w-full border-collapse ">
            <thead>
              <tr>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  S/N
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  FullName
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Email
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Role
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Joined At
                </th>

                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Manage
                </th>
                {/* <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Delete
                </th> */}
              </tr>
            </thead>

            <tbody>
              {users?.map((user, index) => (
                <React.Fragment>
                  <tr>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left text-blueGray-700 ">
                      {index + 1}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left text-blueGray-700 ">
                      {`${user?.firstName} ${user?.secondName}`}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                      {user?.email}
                    </td>

                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2">
                      <i class="fas fa-arrow-up text-emerald-500 mr-4"></i>
                      {user?.role}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                      {user?.status}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                      {moment(user?.createdAt).format("ll")}
                    </td>

                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left text-blueGray-700">
                      <button
                        type="button"
                        onClick={() => handleManage(user)}
                        className="upload-cheque-btn"
                        style={{
                          backgroundColor: "orange",
                          color: "#fff",
                          border: "none",
                        }}
                      >
                        Manage
                      </button>
                    </td>
                    {/* <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left text-blueGray-700">
                      <button
                        className="upload-cheque-btn"
                        style={{
                          backgroundColor: "red",
                          color: "#fff",
                          border: "none",
                        }}
                      >
                        Delete
                      </button>
                    </td> */}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminUsersSection;
