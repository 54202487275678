import Template from "../components/Template";
import LocalSection from "../components/LocalSection";

const LocalTransfer = () => {
  return (
    <>
      <Template />
      <LocalSection />
    </>
  );
};

export default LocalTransfer;
