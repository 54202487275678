import Navbar from "../components/Navbar";
import globalImg from "../assets/global.jpg";
import sidebarImgae from "../assets/sidebar.jpg";
import sideImage2 from "../assets/sidebar2.jpg";

const HomeInvestment = () => {
  return (
    <>
      <Navbar />

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            {/* <h2>ABOUT US</h2> */}
            <img src={globalImg} alt="about" />
            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              MONTHLY SAVING PLAN
            </h3>
            <p>
              A great way to save! Designed as a bond alternative, this
              convenient plan can be purchased through a payroll savings plan or
              automatic transfers.
            </p>

            <div className="flex flex-col lg:flex-row lg:justify-around">
              <img src={sidebarImgae} alt="morgage" className="mb-4 lg:mb-0" />
              <img src={sideImage2} alt="morgage" />
            </div>

            <h3 className=" mb-2 mt-8 dashboard-label font-bold">Benefits:</h3>

            <ul style={{ listStyle: "initial" }}>
              <li>Save money with NO FEES</li>
              <li>Available anytime during the year</li>
              <li>Withdrawal flexibility – funds available at anytime</li>
              <li>
                ONE withdrawal permitted per month without collapsing plan
              </li>
            </ul>

            <h3 className=" mb-2 mt-8 dashboard-label font-bold">Features:</h3>
            <ul style={{ listStyle: "initial" }}>
              <li>
                The Monthly Savings Account is a convenient way to save for that
                special purchase, vacation, children’s education, or maybe for
                Christmas expenses.
              </li>
              <li>
                The interest rate is competitive with Canada Savings Bonds.
              </li>
              <li>
                Funds can be paid via payroll deductions, direct deposit, over
                the counter, via MemberDirect or ATM.
              </li>
              <li>Goal amounts can be set in multiples of £50.00.</li>
              <li>You can cancel at any time without penalty.</li>
              <li>
                Interest is calculated on the minimum, daily balance and is paid
                monthly.
              </li>
              <li>Regular deposits do not need to be maintained.</li>
              <li>One free withdrawal may be made each month.</li>
              <li>Unlimited access via MemberDirect and TeleService.</li>
              <li>MemberCard debit cards are not permitted on this account.</li>
              <li>Passbook option is available.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeInvestment;
