import masterCard from "../assets/mastercard.png";
import visa from "../assets/visa.png";
import chip from "../assets/chip.jpg";
import { useState } from "react";

const CardSection = () => {
  const [formData, setFormData] = useState({
    cardCompany: "masterCard",
    cardType: "debit",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <div class="p-4 sm:ml-64 content-section">
        <div className="flex flex-wrap items-center" style={{marginTop: "22px"}}>
          {/* Left Column */}
          <div className="w-full md:w-1/2 lg:p-4 sm:p-0 md:p-0 xl:p-4 atmWrapper">
            <div
              className="card p-4"
              style={
                formData.cardCompany === "masterCard"
                  ? { backgroundColor: "#dc3545" }
                  : { backgroundColor: "rgb(44 53 67)" }
              }
            >
              <div className="flex justify-between align-center">
                <h2 className="text-sm mb-2">{formData.cardType}</h2>
                <img
                  src={
                    formData.cardCompany === "masterCard" ? masterCard : visa
                  }
                  alt="master card"
                  style={{ height: "38px", width: "60px" }}
                />
              </div>

              <div className="flex justify-between align-center mt-4">
                <img
                  src={chip}
                  alt="master card"
                  style={{ height: "30px", width: "45px" }}
                />
                <h2 className="text-sm mb-2">CVV: xxx</h2>
              </div>

              <span className="mt-4">3455 **** **** 3594</span>

              <div className="flex justify-between mt-4">
                <span>
                  {" "}
                  <i class="bi bi-caret-left-fill"></i> User
                </span>
                <span>Expiry: xx/xx</span>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="w-full md:w-1/2 lg:p-4 sm:p-0 md:p-0 xl:p-4 ">
            <div className=" p-4" style={{ background: "#fff" }}>
              <form class="">
                <h3 className="mb-8 dashboard-label">Select Card Type</h3>

                <div class="mb-4">
                  <select
                    className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                    required
                    name={"cardCompany"}
                    value={formData.cardCompany}
                    onChange={handleChange}
                  >
                    <option value="masterCard">Master Card</option>
                    <option value="visa">Visa Card</option>
                  </select>
                </div>

                <div class="mb-4">
                  <select
                    className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                    required
                    name={"cardType"}
                    value={formData.cardType}
                    onChange={handleChange}
                  >
                    <option value="debit">Debit</option>
                    <option value="credit">Credit</option>
                  </select>
                </div>

                <button
                  style={{ backgroundColor: "#0b111e" }}
                  type="submit"
                  class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  {/* {isLoading ? <Loader /> : "Submit"} */}
                  Apply for card
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardSection;
