import Navbar from "../components/Navbar";
import globalImg from "../assets/about.jpg";
import sidebarImgae from "../assets/sidebar.jpg";
import sideImage2 from "../assets/sidebar2.jpg";

const HomeMorgage = () => {
  return (
    <>
      <Navbar />

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            {/* <h2>ABOUT US</h2> */}
            <img src={globalImg} alt="about" />
            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              CLOSED MORTGAGE
            </h3>
            <p>
              Choosing a mortgage is as important as choosing your home! We can
              show you the financial options available and help you choose the
              best mortgage to suit your budget, lifestyle and general needs.
            </p>

            <h3 className=" mb-2 mt-8 dashboard-label font-bold">Benefits:</h3>

            <ul style={{ listStyle: "initial" }}>
              <li>
                Save money with open prepayment option during the term of the
                mortgage.
              </li>
              <li>
                Convenient weekly or bi-weekly payment schedules can actually
                save you thousands.
              </li>
              <li>
                Peace of mind with life, disability, critical illness, and loss
                of employment insurance for single or joint coverage.
              </li>
            </ul>

            <h3 className=" mb-2 mt-8 dashboard-label font-bold">Features:</h3>
            <ul style={{ listStyle: "initial" }}>
              <li>Flexible repayment term – choice of amortization.</li>
              <li>Renewal terms of 6 months to 10 years</li>
              <li>Choice of Closed/Open mortgage.</li>
              <li>Competitive rates.</li>
              <li>Pre-approvals available.</li>
              <li>
                Open repayment options available: extra payments, as well as
                payment in full are permitted without penalty, from owner’s own
                funds.
              </li>
              <li>Maximum amortization – 40 years.</li>
              <li>Up to 100% financing available with CMHC insurance</li>
              <li>
                Payments via automatic transfer from Engineering Funds account,
                or Automatic Funds Transfer from another financial institution.
              </li>
              <li>
                Property taxes can be collected and paid each year by
                Engineering Funds on behalf of the owner upon request.
              </li>
            </ul>

            <div className="flex flex-col lg:flex-row lg:justify-around">
              <img src={sidebarImgae} alt="morgage" className="mb-4 lg:mb-0" />
              <img src={sideImage2} alt="morgage" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeMorgage;
