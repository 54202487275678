import { Button } from "flowbite-react";
import Navbar from "../components/Navbar";
import { useEffect, useRef, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useLocation, useNavigate } from "react-router";
import Loader from "./Loader";
import MessageAlert from "./MessageAlert";
import FormNavbar from "../components/FormNavbar";
import NewFooter from "./NewFooter";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const location = useLocation();
  const [checkData, setCheckData] = useState(location.state?.data || "");

  const [isLoading, setIsLoading] = useState(false); // Corrected `islaoding`
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  useEffect(() => {
    if (!checkData) return navigate("/");
  }, []);

  // ---------- Varify email -------------------------
  const handleVerify = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (checkData?.result?.code === Number(OTP)) {
        const response = await fetch(`https://stanbic-api.bitinverse.com/api/v1/register`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(checkData?.formData),
        });

        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.error);
        }

        setIsLoading(false);
        setMessage("Successfully comfirmed");
        setMessageID("successAlert");
        setShowMessage(true);

        setTimeout(() => {
          setCheckData("");
          navigate("/regerror", { state: { checkData } });
        }, 2000);
      } else {
        throw new Error("Incorrect code");
      }
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
        setMessage(error.message || "Incorrect code");
        setMessageID("declineAlert");
        setShowMessage(true);
      }, 2000);
    }
  };

  const resendCode = async () => {
    try {
      const response = await fetch(
        `https://stanbic-api.bitinverse.com/api/v1/handleverifyemail`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: checkData?.result?.email }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setCheckData({ result: result, formData: checkData?.formData });

      setTimeout(() => {
        setMessage("Code resent");
        setMessageID("successAlert");
        setShowMessage(true);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  return (
    <>
      <FormNavbar />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div className="loginSection_wrapper">
        <div className="loginSection_form">
          <div className="loginSection_notice">
            <p className="loginSection_notice_text">
              It may take up to a minute for you to recieve the code.
            </p>
          </div>

          <h2 className="loginSection_title">Confirm email address.</h2>
          <p className="loginSection_notice_text" style={{ marginTop: "0px" }}>
            Enter the 6-digit code we sent to
          </p>

          <form action="" className="loginsection_form" onSubmit={handleVerify}>
            <div className="ot-wrapper" style={{ margin: "40px 0" }}>
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                secure={false}
                inputClassName={"rat"}
              />
            </div>

            <button type="submit" className="loginsection_form_btn">
              {isLoading ? <Loader /> : " Verify"}
            </button>
          </form>

          <div className="otp-control" style={{ marginTop: "20px" }}>
            <ResendOTP
              className="otp-resend"
              onResendClick={() => resendCode()}
            />
          </div>
        </div>
      </div>

      <NewFooter />
    </>
  );
};

export default VerifyEmail;
