import React, { useEffect, useState } from "react";
import useLoans from "../stores/loanStore";
import { Button, Modal } from "flowbite-react";
import Loader from "./Loader";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useNavigate } from "react-router";
import moment from "moment/moment";

const AllLoansSection = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(null);
  const [duration, setDuration] = useState("");
  const updateLoans = useLoans((state) => state.storeLoan);
  const loans = useLoans((state) => state.loans);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://stanbic-api.bitinverse.com/api/v1/getallloans`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
            },
          }
        );

        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.msg || "Something went wrong!");
        }

        updateLoans(result);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  // -------- Reject Loan -----------------
  const handleReject = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(`https://stanbic-api.bitinverse.com/api/v1/rejectloan`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          status: "rejected",
          referenceId: referenceId,
          email: email,
        }),
      });
      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      updateLoans(result);
      setIsLoading(false);
      setOpenModal(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // -------- Reject Loan -----------------
  const handleApprove = async () => {
    setIsSending(true);

    try {
      const response = await fetch(`https://stanbic-api.bitinverse.com/api/v1/approveloan`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          status: "approved",
          referenceId: referenceId,
          email: email,
          amount: amount,
          duration: duration,
        }),
      });
      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      updateLoans(result);
      setIsSending(false);
      setOpenModal(false);
    } catch (error) {
      console.log(error);
      setIsSending(false);
    }
  };

  const handleOpen = (e) => {
    setOpenModal(true);
    setReferenceId(e?.referenceId);
    setEmail(e?.email);
    setAmount(e?.amount);
    setDuration(e?.duration);
  };

  const handleManage = (loan) => {
    // localStorage.removeItem("managedData");
    navigate("/manageLoan", { state: { loan } });
  };

  return (
    <>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Update loan Status of <br />
              <strong>{email}</strong>
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                disabled={isLoading || isSending}
                color="failure"
                onClick={handleReject}
              >
                {isLoading ? <Loader /> : "Reject"}
              </Button>
              <Button
                disabled={isLoading || isSending}
                color="success"
                onClick={handleApprove}
              >
                {isSending ? <Loader /> : "Approve"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div class="p-4 sm:ml-64 dashboard-section">
        <h3 className=" mb-2  dashboard-label">All Loans</h3>

        <div class="block w-full overflow-x-auto">
          <table class="items-center  w-full border-collapse ">
            <thead>
              <tr>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  S/N
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  User
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Amount
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Date
                </th>

                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Action
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Manage
                </th>
              </tr>
            </thead>

            <tbody>
              {loans?.map((loan, index) => (
                <React.Fragment>
                  <tr>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left text-blueGray-700 ">
                      {index + 1}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                      {loan?.email}
                    </td>
                    <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-2">
                      $
                      {parseFloat(loan?.amount).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2">
                      <i class="fas fa-arrow-up text-emerald-500 mr-4"></i>
                      {moment(loan?.createdAt).format("LL")}
                    </td>

                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left text-blueGray-700">
                      {loan.status}
                    </td>

                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left text-blueGray-700">
                      <button
                        type="submit"
                        onClick={() => handleOpen(loan)}
                        className="loginsection_form_btn"
                        style={{
                          backgroundColor: "#435983",
                          color: "#fff",
                          border: "none",
                          height: "40px",
                        }}
                      >
                        Approval
                      </button>
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left text-blueGray-700">
                      <button
                        type="submit"
                        className="loginsection_form_btn"
                        style={{ height: "40px" }}
                        onClick={() => handleManage(loan)}
                      >
                        View
                      </button>
                    </td>

                    {/* <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left text-blueGray-700">
                      <button
                        className="upload-cheque-btn"
                        style={{
                          backgroundColor: "red",
                          color: "#fff",
                          border: "none",
                        }}
                      >
                        Delete
                      </button>
                    </td> */}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AllLoansSection;
