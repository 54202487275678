import AdminTemplate from "../components/AdminTemplate";
import AllLoansSection from "../components/AllLoans";

const AllLoans = () => {
  return (
    <>
      <AdminTemplate />
      <AllLoansSection />
    </>
  );
};

export default AllLoans;
