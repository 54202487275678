const PinSection = () => {
  return (
    <div class="p-4 sm:ml-64 content-section">
      <div className="p-4 produce-form-wrapper">
        <div className="produce-form">
          <form class="">
            <h3 className="mb-8 dashboard-label">Change Pin</h3>
            <div class="mb-4">
              <label
                for="type"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Old Pin
              </label>
              <input
                type="number"
                id="type"
                class="shadow-sm produce-input block w-full p-2.5 "
                // placeholder="Enter the of crop"
                name="type"
                // value={formData.type}
                // onChange={handleChange}
                required
              />
            </div>
            <div class="mb-4">
              <label
                for="price"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                New Pin
              </label>
              <input
                type="number"
                id="price"
                class="shadow-sm produce-input block w-full p-2.5 "
                // placeholder="Enter the price"
                name="price"
                // value={formData.price}
                // onChange={handleChange}
                required
              />
            </div>

            <div class="mb-5">
              <label
                for="availability"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Confirm Pin
              </label>
              <input
                type="number"
                id="availability"
                class="shadow-sm produce-input block w-full p-2.5 "
                // placeholder="Enter availability"
                name="availability"
                // value={formData.availability}
                // onChange={handleChange}
                required
              />
            </div>

            <button
              style={{ backgroundColor: "#0b111e" }}
              type="submit"
              class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {/* {isLoading ? <Loader /> : "Submit"} */}
              Change Pin
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PinSection;
