import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loader from "../components/Loader";
import MessageAlert from "../components/MessageAlert";
import useAuthStore from "../stores/authStore";
import useTransactionStore from "../stores/transactionStore";
import NewFooter from "../components/NewFooter";
import FormNavbar from "../components/FormNavbar";
import { Link } from "react-router-dom";

const Login = () => {
  const login = useAuthStore((state) => state.login);
  const setCrypto = useAuthStore((state) => state.setCrypto);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const user = useAuthStore((state) => state.user);
  const transfer = useTransactionStore((state) => state.transfer);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (isLoggedIn && user.role === "admin") {
      navigate("/allusers");
    } else if (isLoggedIn && user.role === "user") {
      navigate("/dashboard");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const result = await (
        await fetch(`https://stanbic-api.bitinverse.com/api/v1/login`, {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(formData),
        })
      ).json();

      if (result.msg) throw result.msg;

      if (result.profile.role === "admin" || result.profile.role === "super") {
        setMessage("Login Successful");
        setMessageID("successAlert");
        setShowMessage(true);
        login(result.profile);
        setCrypto(result.crypto);
        setIsLoading(false);

        setTimeout(() => {
          navigate("/allusers");
        }, 2000);
      } else if (result?.profile?.status === "pending") {
        navigate("/regerror", { state: { checkData: true } });
      } else {
        setMessage("Login Successful");
        setMessageID("successAlert");
        setShowMessage(true);
        transfer(result.transactions);
        login(result.profile);
        setCrypto(result.crypto);
        setIsLoading(false);

        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      }
    } catch (error) {
      setMessage(error.message ? error.message : error);
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  const forgotPassword = () => {
    navigate("/forgotpassword");
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  return (
    <>
      <FormNavbar />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div className="loginSection_wrapper">
        <div className="loginSection_form">
          <div className="loginSection_notice">
            <h3 className="loginSection_notice_title">
              You've been signed out
            </h3>
            <p className="loginSection_notice_text">
              For your protection, we automatically signed you out of your
              account after a period of inactivity. Sign in below to get back
              into your account.
            </p>
          </div>

          <h2 className="loginSection_title">Welcome Back!</h2>
          <p className="loginSection_notice_text" style={{ marginTop: "0px" }}>
            Please log into your account to continue.
          </p>

          <form action="" className="loginsection_form" onSubmit={handleSubmit}>
            <input
              type="email"
              className="loginsection_form_input"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="password"
              className="loginsection_form_input"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />

            <button type="submit" className="loginsection_form_btn">
              {isLoading ? <Loader /> : " LOG IN"}
            </button>

            <div className="loginsection_form_question">
              <span>
                No account? <Link to={"/signup"}>Sign up today.</Link>{" "}
              </span>
              <span>
                Forgot your password?{" "}
                <Link to={"/forgotpassword"}>Don't worry. We can help.</Link>
              </span>
            </div>
          </form>
        </div>
      </div>

      <NewFooter />
    </>
  );
};

export default Login;
