import img from "../assets/family.webp";

const AdviseSection = () => {
  return (
    <>
      <div className="advice_section">
        <div className="advice_section_container">
          <h1 className="advice_title">Save money while you save tomorrow</h1>
          <img src={img} alt="family" />

          <div className="advice_row">
            <div className="advice_column1">
              <h4 className="advice_column_title">Your money is good here</h4>
              <p>
                When it comes to climate change, your money is power. You can
                feel good knowing that your deposits will not fund fossil fuel
                exploration or production.† By moving money to OptimaHoldin,
                you’re giving the climate a fighting chance.
              </p>
            </div>
            <div className="advice_column2">
              <h4 className="advice_column_title">Pay it forward, for the planet</h4>
              <p>
                Maximize your money's impact with an account that reduces your
                car's emissions¹ and can fund tree planting every time you
                swipe.² So we can all breathe a little easier.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdviseSection;
