import Navbar from "../components/Navbar";
import sidebarImgae from "../assets/sidebar.jpg";
import sideImage2 from "../assets/sidebar2.jpg";
import aboutImg from "../assets/about.jpg";

const HomePersonal = () => {
  return (
    <>
      <Navbar />

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            {/* <h2>ABOUT US</h2> */}
            <img src={aboutImg} alt="about" />
            <h5 className=" mb-2 mt-8 dashboard-label font-bold">Savings</h5>
            <p>
              Do you need an instant access savings account, a savings account
              you can manage online, a tax-free ISA, or a rate that is fixed for
              the duration of your savings? Whatever you are looking for in a
              savings account, our range of savings options could help you save
              towards your goals
            </p>

            <div className="flex flex-col lg:flex-row lg:justify-around">
              <img src={sidebarImgae} alt="morgage" className="mb-4 lg:mb-0" />
              <img src={sideImage2} alt="morgage" />
            </div>

            <h5 className=" mb-2 mt-8 dashboard-label font-bold">
              Fixed Rate Savings
            </h5>

            <ul style={{ listStyle: "initial" }}>
              <li>
                You will earn a fixed rate of interest for the chosen term.
              </li>

              <li>Option to receive your interest monthly.</li>
              <li>No withdrawals until maturity.</li>

              <li>Offer may be withdrawn at any time.</li>
              <li>
                We have 1, 2 and 3 year Britannia Fixed Rate bonds available
              </li>
            </ul>

            <h5 className=" mb-2 mt-8 dashboard-label font-bold">ISAs</h5>
            <ul style={{ listStyle: "initial" }}>
              <li>
                Save up to $5,760 in the 2013/14 tax year – see our range of
                cash ISAs.
              </li>

              <li>
                Save up to $11,520 in a stocks and shares ISA in the 2013/2014
                tax year (less any amount you may have already saved in a cash
                ISA).
              </li>
              <li>
                To open a cash ISA you must be aged 16 or over, and to open a
                stocks and shares ISA you must be aged 18 or over. You must be
                resident in the UK and an ISA cannot be held in joint names.
              </li>
            </ul>

            <h5 className=" mb-2 mt-8 dashboard-label font-bold">
              Easy Access Savings
            </h5>

            <ul style={{ listStyle: "initial" }}>
              <li>Britannia Select Access Saver 4</li>

              <li>Smartsaver – Open for as little as $1</li>
              <li>
                You have to be aged 16 or over, and a UK resident to open these
                accounts
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePersonal;
