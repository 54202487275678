import img from "../assets/goodbye-fees-medium.webp"



const FeesSection = () => {
  return (
    <>
      <div className="fees_section_wrapper">
        <div className="fees_row">
          <div className="fees_column">
            <h2 className="fees_column_title">Say farewell to fees</h2>
            <ul className="fees_column_list">
              <li> No ACH transfer fees*</li>
              <li> No monthly fees on standard accounts</li>
              <li> No minimum balance fees</li>
              <li> No in-network ATM fees</li>
              <li> No Allpoint®️ ATM cash deposit fees</li>
            </ul>

            <p className="fees_column_text">
              For any extra services such as a wire transfer, OptimaHoldin commits
              to "All Extra Services Provided at Cost," meaning that we'll only
              charge you what it costs us to provide the extra service, and not
              a penny more.{" "}
            </p>
          </div>
          <div className="fees_column2">
            <img
              src={img}
              alt="fees illustration"
              className="fees_column_image"
              />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeesSection;
