import logo_1 from "../assets/OT_ogo_blk.png";
import logo_2 from "../assets/fortune-logo.svg";
import logo_3 from "../assets/forbes-logo.svg";
import logo_4 from "../assets/insider-logo.svg";
import logo_5 from "../assets/variety-logo.svg";
import logo_6 from "../assets/los-angeles-times-logo.svg";


const Sponsors = () => {
  return (
    <>
      <div className="sponsors_wrapper">
        <div className="sponsors_img_container">
          <div className="img_case">
            <img src={logo_1} alt="sponsors" className="sponsors_img" style={{width: "228px"}}/>
          </div>

          <div className="img_case">
            {" "}
            <img src={logo_2} alt="sponsors" className="sponsors_img" />
          </div>
          <div className="img_case">
            {" "}
            <img src={logo_3} alt="sponsors" className="sponsors_img" />
          </div>
          <div className="img_case">
            <img src={logo_4} alt="sponsors" className="sponsors_img" />
          </div>
          <div className="img_case">
            <img src={logo_5} alt="sponsors" className="sponsors_img" />
          </div>
          <div className="img_case">
            {" "}
            <img src={logo_6} alt="sponsors" className="sponsors_img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sponsors;
