import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import logoDark from "../assets/optima_logo_dark.png"

export default function Navbar() {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleExternalLink = () => {
    window.location.href = "https://orbitaltrades.com/";
  };

  return (
    <>
      {isShow && (
        <div className="fixed z-20 inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setIsShow(false)}
            >
              ✕
            </button>
            <h2 className="text-xl font-semibold">Leaving Our Site</h2>
            <p className="mt-2 text-gray-600">
              You're about to visit <strong>OrbitalTrades</strong>, a
              third-party investment platform that is one of our partners.
              Please note that we are not responsible for their content,
              policies, or services.
            </p>
            <button
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              onClick={handleExternalLink}
            >
              Continue
            </button>
          </div>
        </div>
      )}

      <nav
        className={`fixed w-full z-10 transition-all duration-300 ${
          scrolled ? "bg-white text-gray-800" : "bg-transparent text-white"
        }`}
      >
        <div
          className={`container-fluid mx-auto px-4 py-6 ${
            isOpen ? "bg-white" : ""
          }`}
        >
          <div className="flex justify-between items-center">
            {/* Expanded Navbar */}
            <div
              className="flex items-center space-x-24"
              style={{ fontSize: "0.7rem", fontWeight: "bold" }}
            >
              <Link
                to="/"
                className={`font-bold text-xl scrolled_link ${
                  isOpen ? "scrolled_link" : ""
                }`}
              >
                {/* OptimaHoldin */}
                <img src={logoDark} alt="logo" style={{width: '123px'}}/>
              </Link>
            </div>

            <div className="flex items-center space-x-4">
              <div className={`md:block ${isOpen ? "hidden" : "hidden"}`}>
                <button
                  onClick={handleSignup}
                  className={`px-4 py-2 rounded scrolled_signup_btn hover:bg-opacity-90 ${
                    isOpen ? "scrolled_signup_btn" : ""
                  }`}
                  id="zero_padding"
                >
                  GET STARTED
                </button>
              </div>

              <div className="md:hidden">
                <button
                  onClick={toggleMenu}
                  className={`focus:outline-none scrolled_link`}
                >
                  {isOpen ? (
                    <X size={44} strokeWidth={1} />
                  ) : (
                    <Menu size={44} strokeWidth={1} />
                  )}
                </button>
              </div>
            </div>
          </div>
          {isOpen && (
            <div className="md:hidden mt-4">
              <Link
                to="/"
                className={`block py-2  ${isOpen ? "scrolled_link" : ""}`}
              >
                Home
              </Link>
              <Link
                to="/aboutus"
                className={`block py-2  ${isOpen ? "scrolled_link" : ""}`}
              >
                About
              </Link>

              <Link
                onClick={() => setIsShow(true)}
                className={`block py-2  ${isOpen ? "scrolled_link" : ""}`}
              >
                Investing
              </Link>

              <button
                onClick={handleSignup}
                className={`mt-4 w-full px-4 py-2 rounded scrolled_signup_btn `}
              >
                Get Started
              </button>
            </div>
          )}
        </div>
      </nav>
    </>
  );
}
