import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const useDepositSore = create(
  devtools(
    persist(
      (set) => ({
        deposits: [],

        storeDeposit: (items) =>
          set((state) => ({
            deposits: items,
          })),

        clearDeposits: () =>
          set(() => ({
            deposits: [],
          })),
      }),
      { name: "deposits" }
    )
  )
);

export default useDepositSore;
