import React, {  useMemo } from "react";

const TradingViewWidget = () => {
  const marqueeWidget = useMemo(() => (
    <coingecko-coin-price-marquee-widget
      coin-ids="bitcoin,ethereum,eos,ripple,litecoin"
      currency="usd"
      locale="en"
    ></coingecko-coin-price-marquee-widget>
  ), []);

  return (
    <div>
      {marqueeWidget}
    </div>
  );
};

export default TradingViewWidget;
