import icon1 from "../assets/tag-favorite.png";
import icon2 from "../assets/piggy.png";
import icon3 from "../assets/sunrise.png";
import icon4 from "../assets/atm.png";
import icon5 from "../assets/phone-money.png";
import icon6 from "../assets/giving.png";

const ProtectionSection = () => {
  return (
    <>
      <div className="protection_section_wrapper">
        <div className="proctection_section_container">
          <h2 className="proctection_section_title">
            It pays to protect the planet
          </h2>

          <div className="proctection_section_row">
            {/* ----------- Col ------------ */}
            <div className="proctetion_section_col">
              <div className="proctetion_section_col_icon">
                <img src={icon1} alt="icon" className="protection_img" />
              </div>

              <h4 className="proctetion_section_col_title">
                Up to 6% Cash Back³
              </h4>

              <p className="proctetion_section_col_text">
                Unlimited cash back at over 100 climate-friendly brands in our
                Green Marketplace
              </p>
            </div>

            {/* ----------- Col ------------ */}
            <div className="proctetion_section_col">
              <div className="proctetion_section_col_icon">
                <img src={icon2} alt="icon" className="protection_img" />
              </div>

              <h4 className="proctetion_section_col_title">
                Earn up to 3% APY⁴
              </h4>

              <p className="proctetion_section_col_text">
                Put your money to work with our high-yield savings account.
                Terms and conditions apply.
              </p>
            </div>

            {/* ----------- Col ------------ */}
            <div className="proctetion_section_col">
              <div className="proctetion_section_col_icon">
                <img src={icon3} alt="icon" className="protection_img" />
              </div>

              <h4 className="proctetion_section_col_title">Get Paid Early⁵</h4>

              <p className="proctetion_section_col_text">
                Your paychecks arrive up to 2 days early when you set up Direct
                Deposit.
              </p>
            </div>

            {/* ----------- Col ------------ */}
            <div className="proctetion_section_col">
              <div className="proctetion_section_col_icon">
                <img src={icon4} alt="icon" className="protection_img" />
              </div>

              <h4 className="proctetion_section_col_title">
                55,000 Fee-Free ATMs
              </h4>

              <p className="proctetion_section_col_text">
                Unlimited withdrawals at over 55,000 Allpoint ATMs worldwide,
                with cash deposits available at select locations. (other account
                fees apply).
              </p>
            </div>

            {/* ----------- Col ------------ */}
            <div className="proctetion_section_col">
              <div className="proctetion_section_col_icon">
                <img src={icon5} alt="icon" className="protection_img" />
              </div>

              <h4 className="proctetion_section_col_title">Feature-Packed</h4>

              <p className="proctetion_section_col_text">
                Manage your finances and measure your environmental impact on
                the go.
              </p>
            </div>

            {/* ----------- Col ------------ */}
            <div className="proctetion_section_col">
              <div className="proctetion_section_col_icon">
                <img src={icon6} alt="icon" className="protection_img" />
              </div>

              <h4 className="proctetion_section_col_title">
                Live Customer Support
              </h4>

              <p className="proctetion_section_col_text">
                Our A-Team is here to help you make the most of your account.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProtectionSection;
