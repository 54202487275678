import star from "../assets/icon-star.png";
import img from "../assets/quotation.png";

const RatingSection = () => {
  return (
    <>
      <div className="rating_sect_wrapper">
        <div>
          <div className="rating_sect_container">
            <div className="stars_row">
              <img src={star} alt="star" className="star_icon" />
              <img src={star} alt="star" className="star_icon" />
              <img src={star} alt="star" className="star_icon" />
              <img src={star} alt="star" className="star_icon" />
              <img src={star} alt="star" className="star_icon" />

              <span id="star_text">4.7 STARS IN APP STORE*</span>
            </div>
          </div>

          <div className="rating_testimony_container">
            <div className="rating_testimony_col1">
              <h3 className="rate_title">What people are saying</h3>
              <h5 className="rate_text">
                Individual action causes a ripple effect. Join a community that
                is creating worldwide impact!
              </h5>
            </div>

            {/* --------- Col---------- */}
            <div className="rating_testimony_col2">
              <div className="flex testimonia">
                <img
                  src={img}
                  alt="quotation"
                  className="quotation"
                  style={{ width: 24, height: 24, marginRight: 10 }}
                />

                <div>
                  <div>
                    You guys are honest and you care about your customers and
                    the world.
                  </div>

                  <div>teven B. Whitewater, WI</div>
                </div>
              </div>
            </div>

            {/* --------- Col---------- */}
            <div className="rating_testimony_col2">
              <div className="flex testimonia">
                <img
                  src={img}
                  alt="quotation"
                  className="quotation"
                  style={{ width: 24, height: 24, marginRight: 10 }}
                />

                <div>
                  <div>
                    You guys are honest and you care about your customers and
                    the world.
                  </div>

                  <div>teven B. Whitewater, WI</div>
                </div>
              </div>
            </div>

            {/* --------- Col---------- */}
            <div className="rating_testimony_col2">
              <div className="flex testimonia">
                <img
                  src={img}
                  alt="quotation"
                  className="quotation"
                  style={{ width: 24, height: 24, marginRight: 10 }}
                />

                <div>
                  <div>
                    You guys are honest and you care about your customers and
                    the world.
                  </div>

                  <div>teven B. Whitewater, WI</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RatingSection;
