import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const useTransactionStore = create(
  devtools(
    persist(
      (set) => ({
        transactions: [],

        transfer: (transaction) =>
          set((state) => ({
            transactions: transaction,
          })),
          
        clearTransactions: () =>
          set(() => ({
            transactions: [],
          })),
      }),
      { name: "transactions" }
    )
  )
);

export default useTransactionStore;
