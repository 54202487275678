import { useNavigate } from "react-router";
import Template from "../components/Template";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/loan");
  };

  return (
    <>
      <Template />

      <div class="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <div className="flex justify-end">
              <i
                className="bi bi-x-lg"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={handleCancel}
              ></i>
            </div>
            <h3 className="mt-8 mb-8 dashboard-label ">
              Loans Terms Condition
            </h3>

            <div className="loginsection_form_question">
              <h5>1. Introduction</h5>
              <p>
                Welcome to OptimaHoldin. These Terms and Conditions govern the
                application and processing of loan requests through our online
                platform. By applying for a loan, you agree to comply with these
                terms. Please read them carefully before proceeding.
              </p>

              <h5>2. Eligibility</h5>
              <p>To apply for a loan, you must:</p>
              <ul className="fees_column_list">
                <li>Be at least 18 years old.</li>
                <li>Have a valid government-issued identification.</li>
                <li>Provide accurate and verifiable financial information.</li>
                <li>Meet any additional requirements specified by the bank.</li>
                <li>
                  Have a valid and active bank account for loan disbursement and
                  repayment.
                </li>
              </ul>

              <h5>3. Loan Application Process</h5>
              <ul className="fees_column_list">
                <li>
                  The applicant must complete the online loan application form
                  with accurate details.
                </li>
                <li>
                  Required documents, including proof of income and
                  identification, must be uploaded.
                </li>
                <li>
                  The bank will review the application and may request
                  additional information.
                </li>
                <li>
                  Approval or rejection will be communicated via email or SMS
                  within 3 business days.
                </li>
                <li>
                  The applicant must provide an active phone number and email
                  for verification and communication purposes.
                </li>
                <li>
                  Any discrepancies in the provided details may lead to
                  rejection of the application.
                </li>
              </ul>

              <h5>4. Loan Terms and Repayment</h5>
              <ul className="fees_column_list">
                <li>
                  Approved loans are subject to the agreed interest rate,
                  repayment schedule, and other applicable charges.
                </li>
                <li>
                  Repayments must be made as per the agreed terms, either in
                  fixed installments or lump sum payments.
                </li>
                <li>
                  Late or missed payments may result in penalties, additional
                  interest charges, or legal action.
                </li>
                <li>
                  The borrower is responsible for ensuring sufficient funds are
                  available for scheduled repayments.
                </li>
                <li>
                  The bank reserves the right to deduct repayments from the
                  borrower's bank account in accordance with the loan agreement.
                </li>
                <li>
                  The borrower may request an extension or restructuring of the
                  loan under certain conditions, subject to approval and
                  additional charges.
                </li>
              </ul>

              <h5>5. Data Privacy and Security</h5>
              <ul className="fees_column_list">
                <li>
                  The bank is committed to protecting your personal and
                  financial information.
                </li>
                <li>All data provided is encrypted and stored securely.</li>
                <li>
                  Your information will not be shared with third parties except
                  as required by law or for processing your loan.
                </li>
                <li>
                  You are responsible for keeping your login credentials
                  confidential and notifying the bank of any unauthorized
                  access.
                </li>
                <li>
                  The bank may use automated systems to assess the borrower’s
                  creditworthiness and risk profile.
                </li>
                <li>
                  The borrower agrees to allow the bank to contact employers,
                  references, or other necessary third parties for verification
                  purposes.
                </li>
              </ul>

              <h5>6. Verification and Fraud Prevention</h5>
              <ul className="fees_column_list">
                <li>
                  The bank reserves the right to verify all submitted
                  information and documents.
                </li>
                <li>
                  Any false or misleading information may lead to application
                  rejection or legal consequences.
                </li>
                <li>
                  Suspicious activities may be reported to relevant authorities.
                </li>
                <li>
                  The borrower must not engage in fraudulent activities such as
                  identity theft or misrepresentation.
                </li>
                <li>
                  The bank may conduct periodic reviews and request updated
                  documentation to ensure continued eligibility.
                </li>
              </ul>

              <h5>7. Loan Approval and Disbursement</h5>
              <ul className="fees_column_list">
                <li>
                  Loan approval is subject to the bank’s discretion and
                  verification process.
                </li>
                <li>
                  Approved funds will be disbursed to the applicant’s provided
                  bank account
                </li>
                <li>
                  The bank is not liable for delays caused by incorrect account
                  details or third-party issues.
                </li>
                <li>
                  The borrower must provide accurate bank details to avoid
                  failed transactions.
                </li>
                <li>
                  The disbursed loan amount may be subject to applicable service
                  fees and taxes.
                </li>
              </ul>

              <h5>8. Loan Default and Consequences</h5>
              <ul className="fees_column_list">
                <li>
                  Failure to repay the loan as per the agreed terms may lead to:
                </li>
                <ul className="fees_column_list">
                  <li>Additional interest and penalties.</li>
                  <li>Legal action for debt recovery.</li>
                  <li>
                    Negative impact on credit score and future loan eligibility.
                  </li>
                  <li>Asset seizure if the loan is secured by collateral.</li>
                  <li>
                    Collection efforts through authorized recovery agencies.
                  </li>
                </ul>
                <li>
                  The borrower agrees that the bank may report loan default to
                  credit bureaus, affecting future borrowing capacity.
                </li>
              </ul>

              <h5>9. Fees and Charges</h5>
              <ul className="fees_column_list">
                <li>
                  The borrower agrees to pay all applicable loan processing
                  fees, interest rates, and penalty charges.
                </li>
                <li>
                  Early repayment of the loan may be subject to prepayment fees.
                </li>
                <li>
                  Additional fees may apply for late payments, restructuring, or
                  refinancing of the loan.
                </li>
                <li>
                  The bank will disclose all fees before loan approval, and the
                  borrower must agree to these terms before disbursement.
                </li>
              </ul>

              <h5>10. Amendments to Terms</h5>
              <ul className="fees_column_list">
                <li>
                  The bank reserves the right to update these Terms and
                  Conditions at any time.
                </li>
                <li>
                  Any changes will be communicated through our website or email
                  notifications.
                </li>
                <li>
                  Continued use of the loan services after changes are made
                  implies acceptance of the updated terms
                </li>
              </ul>

              <h5>11. Customer Obligations</h5>
              <ul className="fees_column_list">
                <li>
                  The borrower must provide truthful and complete information
                  throughout the loan application and repayment period.
                </li>
                <li>
                  The borrower must notify the bank of any changes in financial
                  status that may affect repayment ability.
                </li>
                <li>
                  The borrower must read and understand all terms before
                  agreeing to the loan agreement.
                </li>
              </ul>

              <h5>Dispute Resolution</h5>
              <ul className="fees_column_list">
                <li>
                  Any disputes arising from the loan agreement shall be resolved
                  through mediation before legal action.
                </li>
                <li>
                  The borrower agrees to abide by the dispute resolution
                  procedures outlined by the bank.
                </li>
                <li>
                  The bank reserves the right to take legal action in the event
                  of unresolved disputes.
                </li>
              </ul>

              <h5>13. Governing Law</h5>
              <ul className="fees_column_list">
                <li>
                  These Terms and Conditions are governed by the laws of the
                  U.S.A Government
                </li>
                <li>
                  Any legal proceedings must be conducted within the
                  jurisdiction specified by the bank.
                </li>
              </ul>

              <h5> Contact Information</h5>
              <p>
                For any inquiries, assistance, or disputes, please contact our
                support team at{" "}
                <span style={{ fontWeight: "900", color: "#cb5608" }}>
                  support@optimaholdin.com
                </span>{" "}
                or use the{" "}
                <span style={{ fontWeight: "900", color: "#cb5608" }}>
                  live chat
                </span>{" "}
                feature on our website for instant assistance. By submitting
                your loan application, you confirm that you have read,
                understood, and agree to these Terms and Conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
