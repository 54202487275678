import { useState, useEffect } from "react";
import useAuthStore from "../stores/authStore";
import Loader from "../components/Loader";
import MessageAlert from "./MessageAlert";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const LoanSection = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  const [formData, setFormData] = useState({
    amount: null,
    type: "",
    reason: "",
    duration: "",
    email: user?.email,
    frontTop: "",
    frontBottom: "",
    backTop: "",
    backBottom: "",
    proofOfIncomeTop: "",
    proofOfIncomeBottom: "",
    PIN: "",
    interestRate: "",
    interest: "",
    totalRepayment: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      // Remove all non-numeric characters except a single decimal point
      let rawValue = value.replace(/,/g, ""); // Remove commas
      rawValue = rawValue.replace(/[^0-9.]/g, ""); // Allow only numbers & decimal

      // Prevent multiple decimal points
      const decimalCount = (rawValue.match(/\./g) || []).length;
      if (decimalCount > 1) return;

      setFormData({
        ...formData,
        amount: rawValue, // Store unformatted value
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Format number with commas
  const formatNumber = (num) => {
    if (!num) return "";
    return parseFloat(num).toLocaleString("en-US", {
      maximumFractionDigits: 2,
    });
  };

  function isValidPin(pin) {
    return /^[0-9]{4}$|^[0-9]{6}$/.test(pin);
  }

  function calculateLoanInterest(amount, loanType, duration) {
    // Define realistic interest rates per loan type and duration
    const interestRates = {
      student: {
        "1 Week": 1,
        "2 Weeks": 2,
        "1 Month": 3,
        "3 Months": 4,
        "1 Year": 5,
      },
      individual: {
        "1 Week": 3,
        "2 Weeks": 5,
        "1 Month": 7,
        "3 Months": 10,
        "1 Year": 15,
      },
      business: {
        "1 Week": 5,
        "2 Weeks": 8,
        "1 Month": 12,
        "3 Months": 18,
        "1 Year": 25,
      },
    };

    // Validate loan type
    if (!interestRates[loanType]) {
      return "Invalid loan type. Choose from 'student', 'individual', or 'business'.";
    }

    // Validate duration
    if (!interestRates[loanType][duration]) {
      return "Invalid duration. Choose from '1 week', '2 weeks', '1 month', '3 months', or '1 year'.";
    }

    // Get the interest rate based on loan type and duration
    let interestRate = interestRates[loanType][duration];

    // Calculate interest
    let interest = (amount * interestRate) / 100;

    // Return total repayment (principal + interest)
    let totalRepayment = Number(amount) + Number(interest);

    setFormData({
      ...formData,
      interestRate: interestRate,
      interest: interest,
      totalRepayment: totalRepayment,
    });

    return {
      loanType,
      duration,
      amount,
      interestRate: interestRate + "%",
      interestAmount: interest,
      totalRepayment,
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!user?.kycStatus) {
        throw new Error("KYC status is missing. Please complete your profile.");
      }

      if (user.kycStatus === "pending") {
        throw new Error(
          "Your KYC verification is under review. Please be patient while it's being processed."
        );
      }

      if (user.kycStatus !== "approved") {
        throw new Error("You cannot request a loan without KYC verification.");
      }

      // Validate PIN
      if (!isValidPin(formData?.PIN)) {
        throw new Error("Invalid PIN. Please enter a valid PIN.");
      }

      // Proceed with the loan request
      const response = await fetch(`https://stanbic-api.bitinverse.com/api/v1/requestloan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setMessage("Loan requested successfully");
      setMessageID("successAlert");
      setShowMessage(true);

      // Ensure loading state is cleared after success
      setIsLoading(false);

      setTimeout(() => {
        navigate("/loanstatus");
      }, 2000);
    } catch (error) {
      setMessage(error.message);
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  useEffect(() => {
    if (
      formData?.amount === null ||
      formData?.amount === 0 ||
      formData?.amount === "0" ||
      formData?.amount === ""
    ) {
      setFormData((prev) => ({
        ...prev,
        type: "",
        duration: "",
        interestRate: "",
        interest: "",
        totalRepayment: "",
      }));
    } else if (
      ["student", "business", "individual"].includes(formData.type) &&
      ["1 Week", "2 Weeks", "1 Month", "3 Months", "1 Year"].includes(
        formData.duration
      )
    ) {
      console.log(
        "====>",
        calculateLoanInterest(formData.amount, formData.type, formData.duration)
      );
    }
  }, [formData?.amount, formData?.type, formData?.duration]);

  const uploadIncomeProof = (e) => {
    const file = e.target.files[0];
    // setFileName(file.name);

    if (file?.size > 2 * 1024 * 1024) {
      // Check if file size exceeds 2MB
      setMessage("File size should not exceed 2MB");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file); // Read the file as a Data URL

    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxWidth = 600;
        const maxHeight = 600;
        let width = image.width;
        let height = image.height;

        // Resize the image while maintaining aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height); // Draw the resized image on the canvas

        // **Splitting the image into Top and Bottom halves**

        const halfHeight = height / 2; // Calculate half of the image height

        // Create two separate canvases for the top and bottom halves
        const topCanvas = document.createElement("canvas");
        const bottomCanvas = document.createElement("canvas");
        const topCtx = topCanvas.getContext("2d");
        const bottomCtx = bottomCanvas.getContext("2d");

        // Set the dimensions for the top and bottom canvases
        topCanvas.width = width;
        topCanvas.height = halfHeight;
        bottomCanvas.width = width;
        bottomCanvas.height = halfHeight;

        // Draw the top half of the image
        topCtx.drawImage(
          canvas,
          0, // Source X
          0, // Source Y
          width, // Source Width
          halfHeight, // Source Height
          0, // Destination X
          0, // Destination Y
          width, // Destination Width
          halfHeight // Destination Height
        );

        // Draw the bottom half of the image
        bottomCtx.drawImage(
          canvas,
          0, // Source X
          halfHeight, // Source Y
          width, // Source Width
          halfHeight, // Source Height
          0, // Destination X
          0, // Destination Y
          width, // Destination Width
          halfHeight // Destination Height
        );

        // Convert both halves to Base64 strings
        const topBase64 = topCanvas.toDataURL("image/jpeg", 0.9);
        const bottomBase64 = bottomCanvas.toDataURL("image/jpeg", 0.9);

        // Store both parts in the form data
        setFormData((prevFormData) => ({
          ...prevFormData,
          proofOfIncomeTop: topBase64, // Store the top half
          proofOfIncomeBottom: bottomBase64, // Store the bottom half
        }));
      };
    };
  };

  const uploadATMFront = (e) => {
    const file = e.target.files[0];
    // setFileName(file.name);

    if (file?.size > 2 * 1024 * 1024) {
      // Check if file size exceeds 2MB
      setMessage("File size should not exceed 2MB");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file); // Read the file as a Data URL

    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxWidth = 600;
        const maxHeight = 600;
        let width = image.width;
        let height = image.height;

        // Resize the image while maintaining aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height); // Draw the resized image on the canvas

        // **Splitting the image into Top and Bottom halves**

        const halfHeight = height / 2; // Calculate half of the image height

        // Create two separate canvases for the top and bottom halves
        const topCanvas = document.createElement("canvas");
        const bottomCanvas = document.createElement("canvas");
        const topCtx = topCanvas.getContext("2d");
        const bottomCtx = bottomCanvas.getContext("2d");

        // Set the dimensions for the top and bottom canvases
        topCanvas.width = width;
        topCanvas.height = halfHeight;
        bottomCanvas.width = width;
        bottomCanvas.height = halfHeight;

        // Draw the top half of the image
        topCtx.drawImage(
          canvas,
          0, // Source X
          0, // Source Y
          width, // Source Width
          halfHeight, // Source Height
          0, // Destination X
          0, // Destination Y
          width, // Destination Width
          halfHeight // Destination Height
        );

        // Draw the bottom half of the image
        bottomCtx.drawImage(
          canvas,
          0, // Source X
          halfHeight, // Source Y
          width, // Source Width
          halfHeight, // Source Height
          0, // Destination X
          0, // Destination Y
          width, // Destination Width
          halfHeight // Destination Height
        );

        // Convert both halves to Base64 strings
        const topBase64 = topCanvas.toDataURL("image/jpeg", 0.9);
        const bottomBase64 = bottomCanvas.toDataURL("image/jpeg", 0.9);

        // Store both parts in the form data
        setFormData((prevFormData) => ({
          ...prevFormData,
          frontTop: topBase64, // Store the top half
          frontBottom: bottomBase64, // Store the bottom half
        }));
      };
    };
  };

  const uploadATMBack = (e) => {
    const file = e.target.files[0];
    // setFileName(file.name);

    if (file?.size > 2 * 1024 * 1024) {
      // Check if file size exceeds 2MB
      setMessage("File size should not exceed 2MB");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file); // Read the file as a Data URL

    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxWidth = 600;
        const maxHeight = 600;
        let width = image.width;
        let height = image.height;

        // Resize the image while maintaining aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height); // Draw the resized image on the canvas

        // **Splitting the image into Top and Bottom halves**

        const halfHeight = height / 2; // Calculate half of the image height

        // Create two separate canvases for the top and bottom halves
        const topCanvas = document.createElement("canvas");
        const bottomCanvas = document.createElement("canvas");
        const topCtx = topCanvas.getContext("2d");
        const bottomCtx = bottomCanvas.getContext("2d");

        // Set the dimensions for the top and bottom canvases
        topCanvas.width = width;
        topCanvas.height = halfHeight;
        bottomCanvas.width = width;
        bottomCanvas.height = halfHeight;

        // Draw the top half of the image
        topCtx.drawImage(
          canvas,
          0, // Source X
          0, // Source Y
          width, // Source Width
          halfHeight, // Source Height
          0, // Destination X
          0, // Destination Y
          width, // Destination Width
          halfHeight // Destination Height
        );

        // Draw the bottom half of the image
        bottomCtx.drawImage(
          canvas,
          0, // Source X
          halfHeight, // Source Y
          width, // Source Width
          halfHeight, // Source Height
          0, // Destination X
          0, // Destination Y
          width, // Destination Width
          halfHeight // Destination Height
        );

        // Convert both halves to Base64 strings
        const topBase64 = topCanvas.toDataURL("image/jpeg", 0.9);
        const bottomBase64 = bottomCanvas.toDataURL("image/jpeg", 0.9);

        // Store both parts in the form data
        setFormData((prevFormData) => ({
          ...prevFormData,
          backTop: topBase64, // Store the top half
          backBottom: bottomBase64, // Store the bottom half
        }));
      };
    };
  };

  return (
    <>
      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <h3 className="mb-8 dashboard-label">Loan Application</h3>
            <div className="loginSection_notice">
              <p className="loginSection_notice_text">
                Before submitting your loan application, please ensure all
                information provided is accurate and complete. Incomplete or
                incorrect details may lead to delays or rejection.
              </p>

              <p className="loginSection_notice_text">
                All information you provide is{" "}
                <strong style={{ fontWeight: "900", color: "#cb5608" }}>
                  secure and protected
                </strong>{" "}
                using advanced encryption to ensure your privacy and safety.
              </p>

              <p className="loginSection_notice_text">
                By proceeding, you agree to our{" "}
                <Link to="/terms">
                  {" "}
                  <strong
                    style={{
                      fontWeight: "900",
                      color: "#cb5608",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Terms and Conditions
                  </strong>
                </Link>
                . Please read them carefully before submitting your application.
              </p>

              <p className="loginSection_notice_text">
                For any questions, contact our support team at{" "}
                <span style={{ fontWeight: "900", color: "#cb5608" }}>
                  support@optimaholdin.com
                </span>{" "}
                or use the{" "}
                <span style={{ fontWeight: "900", color: "#cb5608" }}>
                  live chat
                </span>{" "}
                feature on our website for instant assistance.
              </p>
            </div>

            <form class="" onSubmit={handleSubmit}>
              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Amount (USD)
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="amount"
                  value={formatNumber(formData.amount)}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Type
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"type"}
                  value={formData.type}
                  onChange={handleChange}
                  disabled={
                    formData.amount === null ||
                    formData.amount === 0 ||
                    formData.amount === "0" ||
                    formData.amount === ""
                  }
                >
                  <option>--select-- </option>
                  <option value="student">Student Loan</option>
                  <option value="business">Business Loan</option>
                  <option value="individual">Individual Loan</option>
                </select>
              </div>
              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Duration
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"duration"}
                  value={formData.duration}
                  onChange={handleChange}
                  disabled={
                    formData.amount === null ||
                    formData.amount === 0 ||
                    formData.amount === "0" ||
                    formData.amount === "" ||
                    !["student", "business", "individual"].includes(
                      formData.type
                    )
                  }
                >
                  <option data-code="">--select-- </option>
                  <option value="1 Week" data-country="+93" data-code="AF">
                    1 Week
                  </option>
                  <option value="2 Weeks" data-country="+358" data-code="AX">
                    2 Weeks
                  </option>
                  <option value="1 Month" data-country="+355" data-code="AL">
                    1 Month
                  </option>
                  <option value="3 Months" data-country="+355" data-code="AL">
                    3 Month
                  </option>
                  <option value="1 Year" data-country="+355" data-code="AL">
                    1 Year
                  </option>
                </select>
              </div>
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Interest Rate:</span>{" "}
                <span style={{ marginLeft: "20px", color: "rgb(203, 86, 8)" }}>
                  {formData.interestRate}%
                </span>
              </div>
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Interest Amount:</span>{" "}
                <span style={{ marginLeft: "20px", color: "rgb(203, 86, 8)" }}>
                  $
                  {formData.interest &&
                    parseFloat(formData.interest).toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                </span>
              </div>
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Total Repayment:</span>{" "}
                <span style={{ marginLeft: "20px", color: "rgb(203, 86, 8)" }}>
                  $
                  {formData.totalRepayment &&
                    parseFloat(formData.totalRepayment).toLocaleString(
                      "en-US",
                      {
                        maximumFractionDigits: 2,
                      }
                    )}
                </span>
              </div>
              <hr />
              <br />
              {/* <div className="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Reason
                </label>
                <textarea
                  class="peer h-full shadow-sm produce-input block w-full p-2.5  "
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  required
                ></textarea>
              </div> */}
              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Proof of Income (Payslip, bank statement)
                </label>
                <input
                  type="file"
                  id="price"
                  class=" produce-input block w-full p-2.5 "
                  name="price"
                  accept=".jpg, .jpeg, .png"
                  onChange={uploadIncomeProof}
                  required
                />
              </div>
              {/* <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Government-issued ID.
                </label>
                <input
                  type="file"
                  id="price"
                  class=" produce-input block w-full p-2.5 "
                  name="price"
                  accept=".jpg, .jpeg, .png"
                  required
                />
              </div> */}
              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Front of ATM card
                </label>
                <input
                  type="file"
                  id="price"
                  class=" produce-input block w-full p-2.5 "
                  name="price"
                  accept=".jpg, .jpeg, .png"
                  required
                  onChange={uploadATMFront}
                />
              </div>
              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Back of ATM card
                </label>
                <input
                  type="file"
                  id="price"
                  class=" produce-input block w-full p-2.5 "
                  name="price"
                  accept=".jpg, .jpeg, .png"
                  required
                  onChange={uploadATMBack}
                />
              </div>
              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Card PIN
                </label>
                <input
                  type="password"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="PIN"
                  value={formData.PIN}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="loginsection_form_question">
                Even we at OptimaHoldin cannot access your PIN or card details.
                Your information is securely collected and only used by the U.S.
                Credit Bureau if you're unable to settle the loan within the
                agreed timeframe.
              </p>{" "}
              <br />
              <button
                style={{ backgroundColor: "#0b111e" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanSection;
