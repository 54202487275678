import { useEffect } from "react";
import AdminTemplate from "../components/AdminTemplate";
import AllUsersSection from "../components/AllUsersSection";
import useAuthStore from "../stores/authStore";
import { useNavigate } from "react-router";

const AllUsers = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <AdminTemplate />
      <AllUsersSection />
    </>
  );
};

export default AllUsers;
