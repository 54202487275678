import ProfileSection from "../components/ProfileSection";
import Template from "../components/Template";

const Profile = () => {
  return (
    <>
      <Template />
      <ProfileSection />
    </>
  );
};

export default Profile;
