import Template from "../components/Template";
import warning from "../assets/warning.png";
import { useNavigate } from "react-router";

const DeclineTransfer = () => {
  const navigate = useNavigate();

  const handleCancle = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <Template />

      <div class="p-4 sm:ml-64 content-section ">
        <div className="p-4 produce-form-wrapper">
          <div
            className="produce-form pt-2 text-center "
            style={{ color: "gray" }}
          >
            <div className="flex justify-end">
              <i
                class="bi bi-x-lg"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={handleCancle}
              ></i>
            </div>
            <div className="flex justify-center">
              <img
                src={warning}
                alt="done"
                style={{ width: "80px", height: "auto" }}
              />
            </div>

            <div className="text-center mb-6">
              <span style={{ color: "red" }}>This Account is SUSPENDED</span>
            </div>

            <p>
              This account has been temporarily suspended. Please contact
              customer care or write an email to us{" "}
              <span style={{ marginLeft: "20px", color: "rgb(203, 86, 8)" }}>
                {" "}
                support@optimaholdin.com{" "}
              </span>{" "}
              or use the{" "}
              <span
                style={{ marginLeft: "20px", color: "rgb(203, 86, 8)" }}
              ></span>
              <span style={{ marginLeft: "20px", color: "rgb(203, 86, 8)" }}>
                live chat{" "}
              </span>
              feature for instant assistance.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeclineTransfer;
