import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ResetPassword from "./pages/ResetPassword";
import ResetPin from "./pages/ResetPin";
import LocalTransfer from "./pages/LocalTransfer";
import InternationalTransfer from "./pages/InternationalTransfer";
import Loan from "./pages/Loan";
import TransactionHistory from "./pages/TransactionHistory";
import MobileDeposit from "./pages/mobileDeposit";
import LoanStatus from "./pages/LoanStatus";
import Card from "./pages/Card";
import Deposit from "./pages/Deposit";
import Profile from "./pages/Profile";
import KYC from "./pages/KYC";
import AllUsers from "./pages/Allusers";
import AllTransactions from "./pages/AllTransactions";
import AllLoans from "./pages/AllLoans";
import HomePage from "./pages/HomePage";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import RegError from "./pages/RegError";
import AddUser from "./pages/AddUser";
import ManageUser from "./pages/ManageUser";
import SuccessfulTransfer from "./pages/SuccessfulTransfer";
import DeclineTransfer from "./pages/DeclineTransfer";
import useAuthStore from "./stores/authStore";
import { useEffect } from "react";
import Receipt from "./pages/Receipt";
import useTransactionStore from "./stores/transactionStore";
import AboutUs from "./pages/AboutUs";
import HomeLoan from "./pages/HomeLoan";
import HomeInvestment from "./pages/HomeInvestment";
import HomeMorgage from "./pages/HomeMorgage";
import HomeBusiness from "./pages/HomeBusiness";
import HomePersonal from "./pages/HomePersonal";
import UpdateTransaction from "./components/UpdateTransaction";
import VerifyEmail from "./components/VerifyEmail";
import EmailRecover from "./pages/EmailRecover";
import ChangePassword from "./pages/ChangePassword";
import UpdateCrypto from "./pages/UpdateCrypto";
import Deposits from "./pages/Deposits";
import UserDeposits from "./pages/UserDeposits";
import useInactivity from "./components/useInactivity";
import TermsAndConditions from "./pages/TermsAndConditions";
import ManageLoan from "./pages/ManageLoan";

function App() {
  const login = useAuthStore((state) => state.login);
  const user = useAuthStore((state) => state.user);
  const setCrypto = useAuthStore((state) => state.setCrypto);
  const transfer = useTransactionStore((state) => state.transfer);

  // Use the inactivity hook to clear local storage and redirect to login if inactive for 1 hour
  useInactivity(1800000);

  useEffect(() => {
    const refreshUserData = async () => {
      try {
        if (user.email) {
          const result = await (
            await fetch(`https://stanbic-api.bitinverse.com/api/v1/getuserdata`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email: user?.email }),
            })
          ).json();

          login(result?.profile);
          setCrypto(result?.crypto);

          console.log();
          

          transfer(result?.transactions);
          
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    refreshUserData();
  }, [user?.email, login, setCrypto]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/regerror" element={<RegError />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/resetpin" element={<ResetPin />} />
          <Route path="/localtransfer" element={<LocalTransfer />} />
          <Route
            path="/internationaltransfer"
            element={<InternationalTransfer />}
          />
          <Route path="/loan" element={<Loan />} />
          <Route path="/transactionhistory" element={<TransactionHistory />} />
          <Route path="/mobiledeposit" element={<MobileDeposit />} />
          <Route path="/loanstatus" element={<LoanStatus />} />
          <Route path="/card" element={<Card />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/kyc" element={<KYC />} />
          <Route path="/allusers" element={<AllUsers />} />
          <Route path="/alltransactions" element={<AllTransactions />} />
          <Route path="/allLoans" element={<AllLoans />} />
          <Route path="/adduser" element={<AddUser />} />
          <Route path="/manageuser" element={<ManageUser />} />
          <Route path="/successfultransfer" element={<SuccessfulTransfer />} />
          <Route path="/declinetransfer" element={<DeclineTransfer />} />
          <Route path="/receipt" element={<Receipt />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/homeloan" element={<HomeLoan />} />
          <Route path="/homeinvestment" element={<HomeInvestment />} />
          <Route path="/homemorgage" element={<HomeMorgage />} />
          <Route path="/homeBusinesb" element={<HomeBusiness />} />
          <Route path="/homepersonal" element={<HomePersonal />} />
          <Route path="/updatetransactions" element={<UpdateTransaction />} />
          <Route path="/validate" element={<VerifyEmail />} />
          <Route path="/forgotpassword" element={<EmailRecover />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/updatecrypto" element={<UpdateCrypto />} />
          <Route path="/deposits" element={<Deposits />} />
          <Route path="/userdeposits" element={<UserDeposits />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/manageloan" element={<ManageLoan />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
