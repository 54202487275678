import { useEffect, useState } from "react";
import AdminTemplate from "../components/AdminTemplate";
import CountryList from "../components/CountryList";
import Loader from "../components/Loader";
import MessageAlert from "../components/MessageAlert";
import { useNavigate } from "react-router";
import avatar from "../assets/profile.png";
import useAuthStore from "../stores/authStore";

const AddUser = () => {
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState("");
  const [imgUpdated, setImgUpdated] = useState(false);
  const [imgSize, setImgSize] = useState(0);

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [rawImage, setRawImage] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    email: "",
    phone: "",
    profilePicture: null,
    dob: "",
    homeAddress: "",
    officeAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    accountType: "",
    password: "",
    confirmPassword: "",
  });


  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // -------------- Grab image and set the profile image ----------------------
  // const handleAddProfile = (e) => {
  //   if (e.target.files && e.target.files[0]) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(e.target.files[0]);

  //     setRawImage(e.target.files[0]);

  //     reader.onloadend = () => {
  //       const imageDataUrl = reader.result;
  //       const fileSize = e.target.files[0].size;

  //       setProfileImg(imageDataUrl);
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         profilePicture: imageDataUrl,
  //       }));
  //       setImgSize(fileSize);
  //       setImgUpdated(true);
  //     };
  //   }
  // };

// -------------- Handle submit -----------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (formData.password !== formData.confirmPassword)
        throw "Password does not match!";

      const result = await (
        await fetch(`https://stanbic-api.bitinverse.com/api/v1/register`, {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(formData),
        })
      ).json();

      console.log("RES ==>", result);

      if (result.msg) throw result.msg;

      setMessage("User Successfully Created");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsLoading(false);
      navigate("/allusers");
    } catch (error) {
      setMessage(error);
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };


  return (
    <>
      <AdminTemplate />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 sm:ml-64 content-section ">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form onSubmit={handleSubmit}>
              <h2>Add User</h2>
              <h3 className="mb-8 dashboard-label">Personal Information</h3>
              <div className="avatarWrapper flex justify-center">
                <div
                  className="profileBox"
                  style={{
                    backgroundImage: `url('${
                      profileImg ? profileImg : avatar
                    }')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <input
                    type="file"
                    id="profileImage"
                    accept=".jpg, .jpeg, .png"
                    name="profilePicture"
                    onChange={handleChange}
                    hidden
                  />
                  <label htmlFor="profileImage" className="avatarInputIcon">
                    <i className="b bi-pencil "></i>
                  </label>
                </div>
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter the of crop"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Second Name
                </label>
                <input
                  type="text"
                  id="price"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter the price"
                  name="secondName"
                  value={formData.secondName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Profile Picture
                </label>
                <input
                  type="file"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="profilePicture"
                  value={formData.profilePicture}
                  onChange={handleChange}
                  required
                />
              </div> */}

              {/* -------------------- Address Information ------------------------ */}
              <h3 className="mb-8 dashboard-label">Address Information</h3>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Home Address
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="homeAddress"
                  value={formData.homeAddress}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Office Address
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="officeAddress"
                  value={formData.officeAddress}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  City Name
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  State
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Zipcode
                </label>
                <input
                  type="number"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Country of Residence
                </label>
                <CountryList
                  data={{ formData, setFormData, name: "country" }}
                />
              </div>

              {/* -------------------- Account Information ------------------------ */}
              <h3 className="mb-8 dashboard-label">Account Information</h3>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Account Type
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"accountType"}
                  value={formData.accountType}
                  onChange={handleChange}
                >
                  <option value="">-- select --</option>
                  <option value="Savings Account">Savings Account</option>
                  <option value="Chequing Account">Chequing Account</option>
                  <option value="Fixed Deposit Account">Fixed Deposit Account</option>
                </select>
              </div>
              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Password
                </label>
                <input
                  type="password"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                style={{ backgroundColor: "#0b111e" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : "Add User"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
